import React, { useEffect, useState, useRef } from "react"
import { Collapse, Row, Col } from "antd"
import Pagination from "react-js-pagination"
import { notifySearch, transactionHistory } from "../../state/action/currency"
import { useDispatch, useSelector } from "react-redux"
import { getProfile } from "../../state/action/profile"
import * as moment from "moment"
import { NotificationStruct } from "../../state/struct/notifications"
import crossIcon from "../../images/icons/cross.png"
import translate from "../../helpers/language"
import { Link } from "gatsby"
import PromotionCode from "./component/promocode-component"

const { Panel } = Collapse
var FB
let getNotifications = [NotificationStruct]
let notificationRecords = 0
const Inbox = () => {
  const [active, setActive] = useState(1)
  getNotifications = useSelector(state => state.profile.notifications)
  notificationRecords = useSelector(state => state.profile.notificationRecords)
  const getHistory = useSelector(state => state.currency.history)
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!!localStorage.getItem("smartway_auth")) {
        dispatch(getProfile())
        dispatch(
          notifySearch({
            Start: 0,
            Length: 10,
          })
        )
      }
    }
  }, [])

  return (
    <div
      style={{
        display: "flex",
        width: "90%",
        flexFlow: "column",
        margin: "auto",
        minWidth: "300px",
        maxWidth: "1100px",
      }}
    >
      <div className={"background-title"}>
        <h2>{translate("Inbox", "กล่องข้อความ")}</h2>
      </div>
      {notificationRecords ? (
        <div>
          <div className="history-wrapper">
            <Collapse accordion expandIconPosition="right" ghost>
              {getNotifications?.map((data, index) => {
                return (
                  <Panel
                    header={
                      <div className={"col-12"}>
                        <div className={"row"}>
                          <div
                            className={
                              "col-4 col-md-6 text-center d-flex flex-column justify-content-center align-content-center"
                            }
                          >
                            <div>{moment(data.CreatedOnISO).format("DD")}</div>
                            <div>
                              {moment(data.CreatedOnISO).format("MMMM YYYY")}
                            </div>
                          </div>
                          <div className={"col-8 col-md-6"}>
                            <div className={"p-4"}>{data.Subject}</div>
                          </div>
                        </div>
                      </div>
                    }
                    key={data.NotifyMessageId}
                  >
                    <div style={{ overflow: "auto" }}>
                      {data.NotificationPicture && (
                        <div className={"text-center mt-4"}>
                          <img
                            className={"mb-4"}
                            src={data.NotificationPicture}
                            style={{
                              maxWidth: "100%",
                            }}
                          />
                        </div>
                      )}
                      <div
                        className={"mt-4"}
                        dangerouslySetInnerHTML={{ __html: data.Message }}
                      ></div>
                      {data.NotificationTermCondition && (
                        <div
                          className={"mt-4"}
                          dangerouslySetInnerHTML={{
                            __html: data.NotificationTermCondition,
                          }}
                        ></div>
                      )}
                      {data.NotificationLink && (
                        <div className={"text-center mt-4"}>
                          <a href={data.NotificationLink} target={"_blank"}>
                            {data.NotificationLink}
                          </a>
                        </div>
                      )}
                      {data.PromotionCode && (
                        <PromotionCode data={data.PromotionCode} />
                      )}
                    </div>
                  </Panel>
                )
              })}
            </Collapse>
          </div>
          <p>
            {`Showing ${active === 1 ? `1` : active * 10 - 9} to ${
              active * 10 > notificationRecords
                ? notificationRecords
                : active * 10
            } of ${notificationRecords} entries.`}
          </p>
          <Pagination
            activePage={active}
            itemsCountPerPage={10}
            totalItemsCount={notificationRecords}
            pageRangeDisplayed={5}
            onChange={e => {
              setActive(e)
              dispatch(
                notifySearch({
                  Start: e * 10 - 10,
                  Length: 10,
                })
              )
            }}
          />
        </div>
      ) : (
        <div
          className={"text-center font-weight-bold"}
          style={{
            fontSize: "1.5rem",
          }}
        >
          {translate("No Data", "ไม่พบข้อมูล")}
        </div>
      )}
    </div>
  )
}
export default Inbox
