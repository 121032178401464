import crossIcon from "../../../images/icons/cross.png"
import React from "react"
import translate from "../../../helpers/language"
import Swal from "sweetalert2"
import Icon from "antd/lib/icon"

const SlipPreviewComponent = ({ slip, handleCancelNormalModal }) => {
  function downloadImage() {
    // Get the image source URL
    var imageUrl = slip.SlipPreviewUrl

    // Fetch the image as a blob
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a blob URL
        var blobUrl = window.URL.createObjectURL(blob)

        // Create a link element
        var downloadLink = document.createElement("a")

        // Set the href attribute to the blob URL
        downloadLink.href = blobUrl

        // Set the download attribute with a desired filename
        downloadLink.download = "slip.png"

        // Append the link to the body
        document.body.appendChild(downloadLink)

        // Trigger a click on the link to start the download
        downloadLink.click()

        // Remove the link and revoke the blob URL after the download is initiated
        document.body.removeChild(downloadLink)
        window.URL.revokeObjectURL(blobUrl)
      })
      .catch(error => console.error("Error fetching image:", error))
  }

  //func copy text to scriptboard
  const copyToClipboard = async text => {
    if (!navigator.clipboard) {
      const el = document.createElement("textarea")
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)
      alert("Copied the text: " + el.value)
    } else {
      console.log("DEBUG: text", text)
      await navigator.clipboard.writeText(text)
      Swal.fire({
        icon: "success",
        title: "Copied",
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  return (
    <div className="transfer__modal">
      <div
        className={"transfer__modal--select-account"}
        style={{
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "300px",
            alignContent: "center",
            printColorAdjust: "exact",
            position: "relative",
            margin: "auto",
          }}
        >
          <img
            src={slip.SlipPreviewUrl}
            style={{
              width: "320px",
              height: "auto",
            }}
          />
          {/*<div*/}
          {/*  className={"slip-refno text-primary"}*/}
          {/*  onClick={() => copyToClipboard(slip.RefNo)}*/}
          {/*>*/}
          {/*  Copy*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  className={"slip-email text-primary"}*/}
          {/*  onClick={() => copyToClipboard(slip.PayID)}*/}
          {/*>*/}
          {/*  Copy*/}
          {/*</div>*/}
        </div>
        <div
          style={{
            width: "320px",
            padding: "15px 0",
            margin: "auto",
          }}
        >
          {/*copy payid and refNo*/}
          {slip.PayID && (
            <div className={"row"}>
              <div className={"col-12 mt-1"}>
                <a
                  onClick={() => copyToClipboard(slip.PayID)}
                  className="slip-copy-btn col-12 btn-sm bold btn-primary btn-block text-center"
                >
                  <Icon type="copy" />
                  Copy email
                </a>
              </div>
              <div className={"col-12 mt-1"}>
                <a
                  onClick={() => copyToClipboard(slip.RefNo)}
                  className="slip-copy-btn col-12 btn-sm bold btn-primary btn-block text-center text-nowrap"
                >
                  <Icon type="copy" />
                  Copy description
                </a>
              </div>

              <div className={"col-6 align-content text-center mt-1"}>
                <a
                  className="slip-copy-btn col-12 btn-sm bold btn-primary btn-block text-center"
                  onClick={downloadImage}
                >
                  {translate("Download", "ดาวน์โหลด")}
                </a>
              </div>
              <div className={"col-6 align-content text-center mt-1"}>
                <a
                  className="slip-copy-btn col-12 btn-sm bold btn-primary btn-block text-center"
                  onClick={() => handleCancelNormalModal()}
                >
                  {translate("Close", "ปิด")}
                </a>
              </div>
            </div>
          )}
          {// copy account no , RefNo ,BSB
          slip.AccountNo && (
            <div className={"row"}>
              <div className={"col-12 mt-1"}>
                <a
                  onClick={() => copyToClipboard(slip.AccountNo)}
                  className="slip-copy-btn col-12 btn-sm bold btn-success btn-block text-center"
                >
                  <Icon type="copy" />
                  Copy Account No.
                </a>
              </div>
              <div className={"col-12 mt-1"}>
                <a
                  onClick={() => copyToClipboard(slip.BSB)}
                  className="slip-copy-btn col-12 btn-sm bold btn-success btn-block text-center"
                >
                  <Icon type="copy" />
                  Copy BSB
                </a>
              </div>
              <div className={"col-12 mt-1"}>
                <a
                  onClick={() => copyToClipboard(slip.RefNo)}
                  className="slip-copy-btn col-12 btn-sm bold btn-success btn-block text-center"
                >
                  <Icon type="copy" />
                  Copy Reference No.
                </a>
              </div>
              <div className={"col-6 align-content text-center mt-1"}>
                <a
                  className="slip-copy-btn col-12 btn-sm bold btn-success btn-block text-center"
                  onClick={downloadImage}
                >
                  {translate("Download", "ดาวน์โหลด")}
                </a>
              </div>
              <div className={"col-6 align-content text-center mt-1"}>
                <a
                  className="slip-copy-btn col-12 btn-sm bold btn-success btn-block text-center"
                  onClick={() => handleCancelNormalModal()}
                >
                  {translate("Close", "ปิด")}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default SlipPreviewComponent
