import { API_URL, headers, headers1 } from "../../config"
import Swal from "sweetalert2"
import {
  GET_CURENCY,
  EXH_RATE,
  EXH_RATE_CURRENT,
  ALL_BANK,
  ALL_TITLE,
  ALL_STATE,
  ALL_CITY,
  ALL_POSTEL,
  TIME_CONNECT,
  ALL_SALARY,
  ALL_GENDER,
  ALL_OCCUPATION,
  FREQUENCY_TRANSFER,
  ALL_NATIONALITY,
  ALL_HISTORY,
  GET_ADVERTIZE,
  PROFILE_GET_NOTIFICATION,
  PROFILE_GET_TOTAL_NOTIFICATION,
  GET_PAYMENT_METHOD,
} from "../constants/constant"

import axios from "axios"
import { setNestedObjectValues } from "formik"
import { navigate } from "gatsby-link"
import { NotificationStruct } from "../struct/notifications"
import translate from "../../helpers/language"

export const getCurrency = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/GetCurrency`,
        {},

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: GET_CURENCY,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const checkPromoCode = promoData => async () => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(
      `${API_URL}/GetCurrentExchangeRate`,
      promoData,
      {
        headers: headers1(),
      }
    )
    if (response.data.StatusCode === "0") {
      return [response.data, null]
    } else {
      return [response.data, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}

export const getCurrentExchangeRate2 = exhDataCurrent => async dispatch => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(
      `${API_URL}/GetCurrentExchangeRate`,
      exhDataCurrent,
      {
        headers: headers1(),
      }
    )
    if (response.data.StatusCode === "0") {
      dispatch({
        type: EXH_RATE_CURRENT,
        payload: response.data.Object,
      })
      return [response.data.Object, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}
//PaymentMethod
export const getPaymentMethod = () => async dispatch => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(
      `${API_URL}/PaymentMethod`,
      {},
      {
        headers: headers1(),
      }
    )
    if (response.data.StatusCode === "0") {
      dispatch({
        type: GET_PAYMENT_METHOD,
        payload: response.data.Items,
      })
      return [response.data.Items, null]
    } else {
      return [response.data, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}
export const getCurrentExchangeRate = exhDataCurrent => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/GetCurrentExchangeRate`,
        exhDataCurrent,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
        } else {
          Swal.fire({
            title: response.data.Message,
          })
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const checkOverLimit = transferData => async dispatch => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(
      `${API_URL}/TransferMoney/CheckOverLimit`,
      transferData,
      {
        headers: headers1(),
      }
    )
    if (response.data.StatusCode === "0") {
      return [response.data, null]
    } else if (response.data.StatusCode === "09") {
      return [response.data, null]
    }
  } catch (e) {
    return [null, "something went wrong : " + e]
  }
}

export const getAdvertize = () => {
  return axios
    .post(
      `${API_URL}/Advertise`,

      {
        headers: headers1(),
      }
    )
    .then(function(response) {
      // Swal.close()
      if (response.data.StatusCode === "0") {
        return response.data.Object
      }
    })
    .catch(function(error) {
      alert("something went wrong:" + error)
    })
}

export const getAdvertizeWeb = () => {
  return axios
    .post(`${API_URL}/Advertise/web`, {
      headers: headers1(),
    })
    .then(function(response) {
      return response
    })
    .catch(err => Swal.fire(err))
}

export const getExchangeRate = exhData => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/GetSpecialExchangeRate`,
        exhData,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: EXH_RATE,
            payload: response.data.Items,
          })
        } else if (response.data.StatusCode === "0") {
          alert(response.data.Message)
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const getAllBank = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Bank`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_BANK,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const getCity = ProvinceId => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Amphur`,
        { ProvinceId },

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_CITY,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const getPostel = amphurId => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/PostalCode`,
        { amphurId },

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_POSTEL,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const getStates = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Province`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_STATE,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const getGender = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Gender`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_GENDER,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const ConvenientTimeToContact = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/ConvenientTimeToContact`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: TIME_CONNECT,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const FrequencyOfTransfer = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/FrequencyOfTransfer`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: FREQUENCY_TRANSFER,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const allSalary = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Salary`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_SALARY,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const Occupation = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Occupation`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_OCCUPATION,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const nationalityAll = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Nationality`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_NATIONALITY,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const titleAll = () => {
  return dispatch => {
    axios
      .post(
        `${API_URL}/Title`,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_TITLE,
            payload: response.data.Items,
          })
        } else {
          alert(response.data.Message)
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const transactionHistory = (date1, date2, refNo, start, length) => {
  const data = {
    TransferDateFromISO: date1 || "",
    TransferDateToISO: date2 || "",
    TransferMoneyId: null,
    TransactionRefNo: refNo || "",
    Start: start,
    Length: length,
  }

  return dispatch => {
    axios
      .post(
        `${API_URL}/TransferMoneyHistory`,
        data,

        {
          headers: headers1(),
        }
      )
      .then(function(response) {
        let dataDefault = {
          Message: "",
          StatusCode: "",
        }
        dataDefault.Message = response.data.Message
        dataDefault.StatusCode = response.data.StatusCode

        if (response.data.StatusCode === "0") {
          dispatch({
            type: ALL_HISTORY,
            payload: response.data,
          })
          return [response.data, null]
        } else {
          return [dataDefault, dataDefault.Message]
        }
      })
      .catch(function(error) {
        return [null, "something went wrong : " + error]
      })
  }
}

export const transferMoneyCheck = data => async () => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(`${API_URL}/TransferMoney`, data, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0") {
      return [response.data, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}
//TermAndCondition/IsAccept
export const termAndCondition = data => async () => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.get(`${API_URL}/TermAndCondition/IsAccept`, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0") {
      return [response.data, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}
///TermAndCondition/Accept
export const termAndConditionAccept = data => async () => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(`${API_URL}/TermAndCondition/Accept`, data, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0") {
      return [response.data, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}
///NotifySearch
export const notifySearch = (
  data = { Start: 0, Length: 10 }
) => async dispatch => {
  let response = {
    data: {
      Message: "",
      StatusCode: "",
      Items: [NotificationStruct],
      TotalRecords: 0,
    },
  }
  try {
    data.isWebApp = true
    response = await axios.post(`${API_URL}/NotifySearch`, data, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0") {
      dispatch({
        type: PROFILE_GET_NOTIFICATION,
        payload: response.data.Items,
      })
      dispatch({
        type: PROFILE_GET_TOTAL_NOTIFICATION,
        payload: response.data.TotalRecords,
      })
      return [response.data.Items, null]
    } else {
      dispatch({
        type: PROFILE_GET_NOTIFICATION,
        payload: [],
      })
      dispatch({
        type: PROFILE_GET_TOTAL_NOTIFICATION,
        payload: 0,
      })
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}

export const deleteAccount = data => {
  return dispatch => {
    let response = {
      data: {
        Message: "",
        StatusCode: "",
        Items: [NotificationStruct],
        TotalRecords: 0,
      },
    }
    try {
      response = axios.post(`${API_URL}/DeleteMemberAccount`, data, {
        headers: headers1(),
      })
      if (response.data.StatusCode === "0") {
        return [response.data, null]
      } else {
        return [response, response.data.Message]
      }
    } catch (error) {
      return [null, "something went wrong : " + error]
    }
  }
}
