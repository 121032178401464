import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { Link } from "gatsby"
import translate from "src/helpers/language"
import { userLogin, userLoginGoogle } from "../../state/action/login"
import GoogleLogin from "react-google-login"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import googleLogo from "src/images/icons/google-logo.png"
import Swal from "sweetalert2"

const Login = () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.user)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setError(state.error)
  }, [state])
  useEffect(() => {
    const currentUrl = window.location.href
    if (currentUrl.includes("session=expired")) {
      localStorage.removeItem("smartway_auth")
      Swal.fire({
        title: translate("Session Expired", "หมดเวลาการเชื่อมต่อ"),
        text: translate("Please login again", "กรุณาเข้าสู่ระบบอีกครั้ง"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Login",
      }).then(result => {
        window.location.href = "/login"
      })
    }
  }, [])

  // if (typeof window !== 'undefined' && !!localStorage.getItem("smartway_auth") ) {

  //   navigate("/")

  // }

  const startLoginGoogle = async googleTokenId => {
    await dispatch(userLoginGoogle(googleTokenId))
  }

  return (
    <>
      <section className="login-money-transfer"
      style={{
        marginTop: "2rem",
      }}
      >
        <h1>{translate("Login & Start Transfer", "เข้าสู่ระบบและเริ่มโอน")}</h1>

        <Formik
          initialValues={{ email: "", password: "" }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = "Required"
            }
            if (!values.password) {
              errors.password = "Required"
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setError("")
            setLoading(true)
            await dispatch(userLogin(values.email, values.password))
            setLoading(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="login-currency-group currency-group--transfer">
                <div className="currency-input">
                  <input
                    placeholder={translate("Username", "ชื่อผู้ใช้")}
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
                <div className="error-form">
                  {errors.email && touched.email && errors.email}
                </div>
              </div>

              <div className="login-currency-group currency-group--transfer">
                <div className="currency-input">
                  <input
                    placeholder={translate("Password", "รหัสผ่าน")}
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </div>

                {!!error && (
                  <div
                    style={{
                      color: "red",
                      marginTop: "20px",
                      marginBottom: "-20px",
                    }}
                  >
                    {error}
                  </div>
                )}
                <div className="error-form">
                  {errors.password && touched.password && errors.password}
                </div>
              </div>

              <div class="login-link-holder">
                <Link
                  to={
                    typeof window !== "undefined" &&
                    window.location.href.includes("/th")
                      ? "/th/forgot-password"
                      : "/en/forgot-password"
                  }
                >
                  {translate("Forgot your password?", "ลืมรหัสผ่าน ?")}
                </Link>
              </div>
              <div className="login-link-holder">
                <button
                  type="submit"
                  className="btn bold btn--yellow"
                  style={{
                    width: "100%",
                  }}
                >
                  {loading
                    ? translate("Please wait ...", "โปรดรอ ...")
                    : translate("Login", "เข้าสู่ระบบ")}
                </button>
              </div>
            </form>
          )}
        </Formik>

        {/* <div className="login-separator"></div> */}

        {/*<div class="divider"></div>*/}

        {/*<GoogleLogin*/}
        {/*  clientId="544252217290-al01bd8sb7aqqmv9qoahgqsv20tl1eoo.apps.googleusercontent.com"*/}
        {/*  buttonText="Login with Google"*/}
        {/*  render={renderProps => (*/}
        {/*    <div className="login-link-holder login-link-holder--google">*/}
        {/*      <button*/}
        {/*        type="submit"*/}
        {/*        className="transfer__ghost-button btn bold"*/}
        {/*        onClick={renderProps.onClick}*/}
        {/*        disabled={renderProps.disabled}*/}
        {/*        style={{*/}
        {/*          width: "100%",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img src={googleLogo} className="google-logo" />*/}
        {/*        {translate("Login with Google", "เข้าสู่ระบบด้วย Google")}*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*  onSuccess={e => {*/}
        {/*    startLoginGoogle(e.tokenId)*/}
        {/*  }}*/}
        {/*  onFailure={e => {}}*/}
        {/*  cookiePolicy={"single_host_origin"}*/}
        {/*/>*/}
      </section>
    </>
  )
}

export default Login
