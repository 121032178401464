import translate from "../../../helpers/language"
import React from "react"
import crossIcon from "../../../images/icons/cross.png"
import { termAndConditionAccept } from "../../../state/action/currency"
import Swal from "sweetalert2"
import { useDispatch } from "react-redux"

const TermAndCondition = ({
  handleCancelNormalModal,
  handleSuccessMessage,
  bodyHtml,
  termID,
  title,
}) => {
  const dispatch = useDispatch()
  const onAcceptClick = async () => {
    try {
      const [addTransaction, error] = await dispatch(
        termAndConditionAccept({ TermAndConditionId: termID })
      )
      if (error) {
        setTimeout(() => {
          Swal.fire({
            title: "Error !!",
            text: error,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
          })
        }, 500)
      }
      handleSuccessMessage()
    } catch (e) {
      console.log("DEBUG: e", e)
    }
  }
  return (
    <div className="transfer__modal">
      <div className={"transfer__modal--select-account"}>
        <div
          className="transfer__modal--cross"
          onClick={() => handleCancelNormalModal()}
          style={{ top: "20px", right: "20px" }}
        >
          <img src={crossIcon} className="transfer__modal--cross-img" />
        </div>
        <h2>
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </h2>
        <div class="terms">
          <div dangerouslySetInnerHTML={{ __html: bodyHtml }}></div>
        </div>
        <div className={"row"}>
          <div className={"col-10 align-content text-center col-sm-8"}></div>
          <div className={"col-2 align-content text-center col-sm-4"}>
            <a className="btn bold btn--blue " onClick={onAcceptClick}>
              {translate("Accept", "ยอมรับ")}
            </a>
          </div>
          {/*<div className={"col-3 align-content text-center col-sm-6"}>*/}
          {/*    <a*/}
          {/*        className="btn bold btn-outline-dark"*/}
          {/*        onClick={handleCancelNormalModal}*/}
          {/*    >*/}
          {/*        {translate("Cancel", "ยกเลิก")}*/}
          {/*    </a>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  )
}
export default TermAndCondition
