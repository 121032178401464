import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { default as GatsbyImage } from "gatsby-image"
import { MoneyTransfer } from "src/sws-ui"

const Hero = ({
  heading,
  subHeading,
  image,
  button,
  imageAlign = "left",
  row,
}) => {
  // const classes = classNames(
  // 	'hero',
  // 	'col-center col-sm-center',
  // 	`hero--align-${imageAlign}`,
  // 	imageAlign === 'left' ? 'hero--slant-up' : 'hero--slant-down',
  // 	row,
  // );
  const [localValue, setLocalValue] = useState(false)
  const classes = classNames(
    "hero",
    "smartway-container",
    "col-md-12 col-sm-12",
    imageAlign === "left" ? "hero--slant-up" : "hero--slant-down",
    row
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLocalValue(!!localStorage.getItem("smartway_auth"))
    }
  }, [typeof window !== "undefined" && localStorage.getItem("smartway_auth")])

  const wrapperClasses = classNames(
    "hero__wrapper",
    `hero--align-${imageAlign}`
  )

  const heroClasses = classNames(
    "hero__content",
    localValue && "hero__content--center"
  )

  const images = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { relativePath: { regex: "/hero/.*/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 558, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const heroImage = images.allImages.edges.find(n => {
    return n.node.relativePath.includes(image)
  })
  if (!heroImage) return null

  return (
    <>
      <section className={classes} key={heroImage.node.name} >
        <div className={wrapperClasses}>
          <GatsbyImage
            fluid={heroImage.node.childImageSharp.fluid}
            placeholderStyle={{ transition: "unset" }}
            imgStyle={{
              height: "inherit",
              width: "100%",
              transition: "unset"
            }}
          />

          <article className={heroClasses}>
            <h1>{heading}</h1>
            <h2>{subHeading}</h2>
            {!localValue && button}
          </article>
        </div>

        <MoneyTransfer />
      </section>
    </>
  )
}

export default Hero
