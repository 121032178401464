import { API_URL, headers, headers1 } from "../../config"
import * as loginConstant from "../constants/constant"
import Swal from "sweetalert2"
import axios from "axios"
import { navigate } from "gatsby"
import loadScript from "load-script"
import { GET_PAYMENT_METHOD } from "../constants/constant"

let googleMapsPromise
export const contactUs = data => {
  const values = {
    ContactUsName: data.firstName + data.lastName,
    EmailAddress: data.email,
    PhoneNo: data.phone,
    Message: data.question,
  }

  return axios
    .post(`${API_URL}/ContactUs`, values, {
      headers: headers1(),
    })
    .then(function(response) {
      if (response.data.StatusCode === "0") {
        Swal.fire({
          title: "Thank you for your message.",
        })
      }
    })
}

export const editAddress = data => {
  const values = {
    MemberId: parseInt(localStorage.getItem("smartway_user_info")),
    AusUnitNumber: data.unit,
    AusStreetNumber: data.street_number,
    AusStreetName: data.street_name,
    AusStreetType: data.street_type,
    AusSuburb: data.suburb,
    AusState: data.state,
    AusPostalCode: data.postcode,
    AusCountry: data.country,
  }

  return axios.post(`${API_URL}/EditAddress`, values, {
    headers: headers1(),
  })
}
export const getProfile3 = () => async dispatch => {
  console.log("DEBUG: getProfile3")
  await axios
    .post(`${API_URL}/GetProfile/all`, null, {
      headers: headers1(),
    })
    .then(function(response) {
      if (response.data.StatusCode === "0") {
        dispatch({
          type: loginConstant.USER_PROFILE,
          payload: response.data.Object,
        })
      } else if (response.data.StatusCode === "01") {
        if (localStorage.getItem("smartway_auth")){
          window.location.href = "/login?session=expired"
          // console.log('DEBUG: "/login?session=expired"');
        }
        return response
      }
    })
}

export const getProfile = () => async dispatch => {
  console.log('DEBUG: getProfile');
  await axios
    .post(`${API_URL}/GetProfile/all`, null, {
      headers: headers1(),
    })
    .then(function(response) {
      if (response.data.StatusCode === "0") {
        Swal.close()
        // response.data.Object.RegisterStep = 'UploadDocument'
        dispatch({
          type: loginConstant.USER_PROFILE,
          payload: response.data.Object,
        })
      } else if (response.data.StatusCode === "01") {
        if (localStorage.getItem("smartway_auth")){
          window.location.href = "/login?session=expired"
          // console.log('DEBUG: "/login?session=expired"');
        }
      }
    })
    .catch(function(error) {
      alert("something went wrong")
    })
}
export const moneyTransferGetProfile = data => async dispatch => {
  console.log("DEBUG: moneyTransferGetProfile")
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(`${API_URL}/GetProfile/all`, data, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0") {
      dispatch({
        type: loginConstant.USER_PROFILE,
        payload: response.data.Object,
      })
      return [response.data, null]
    } else if (response.data.StatusCode === "01") {
      if (localStorage.getItem("smartway_auth")){
        window.location.href = "/login?session=expired"
        // console.log('DEBUG: "/login?session=expired"');
      }
      return [response, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}

export const getProfileLogin = () => async dispatch => {
  console.log('DEBUG: getProfileLogin');
  await axios
    .post(`${API_URL}/GetProfile/all`, null, {
      headers: headers1(),
    })
    .then(function(response) {
      if (response.data.StatusCode === "0") {
        dispatch({
          type: loginConstant.USER_PROFILE,
          payload: response.data.Object,
        })
        if (
          response.data.Object.MemberStatusId === 1 &&
          response.data.Object.MemberProfileStatusId === null
        ) {
          navigate(
            typeof window !== "undefined" &&
              window.location.href.includes("/th")
              ? "/th/register2"
              : "/en/register2"
          )
        } else if (
          response.data.Object.MemberStatusId === 1 &&
          response.data.Object.MemberProfileStatusId === 3
        ) {
          navigate(
            typeof window !== "undefined" &&
              window.location.href.includes("/th")
              ? "/th"
              : "/"
          )
        } else if (
          response.data.Object.MemberStatusId === 1 &&
          response.data.Object.MemberProfileStatusId === 1
        ) {
          navigate(
            typeof window !== "undefined" &&
              window.location.href.includes("/th")
              ? "/th"
              : "/"
          )
        } else if (
          response.data.Object.MemberStatusId === 1 &&
          response.data.Object.MemberProfileStatusId === 2
        ) {
          navigate(
            typeof window !== "undefined" &&
              window.location.href.includes("/th")
              ? "/th"
              : "/"
          )
        } else {
          navigate(
            typeof window !== "undefined" &&
              window.location.href.includes("/th")
              ? "/th"
              : "/"
          )
        }
      }else if (response.data.StatusCode === "01") {
        if (localStorage.getItem("smartway_auth")){
          window.location.href = "/login?session=expired"
          // console.log('DEBUG: "/login?session=expired"');
        }
      }
    })
    .catch(function(error) {
      alert("something went wrong")
    })
}

export const editProfile = (data, imgRequest) => {
  // Swal.showLoading()
  return dispatch => {
    axios
      .post(`${API_URL}/EditProfile`, data, {
        headers: headers1(),
      })
      .then(function(response) {
        if (response.data.StatusCode == "01") {
          if (localStorage.getItem("smartway_auth")) {
            Swal.fire({
              title: response.data.Message,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Login",
            }).then(result => {
              if (result.isConfirmed) {
                localStorage.removeItem("smartway_auth")
                window.location.href =
                  typeof window !== "undefined" &&
                  window.location.href.includes("/th")
                    ? "/th/login"
                    : "/en/login"
              }
            })
          }
        }
      })
      .catch(function(error) {
        Swal.fire("something went wrong")
      })
  }
}
export const moneyTransferEditProfile = (data, imgRequest) => async () => {
  // Swal.showLoading()
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(`${API_URL}/EditProfile`, data, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0") {
      return [response.data, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}

//register

export const changePhoneno = (mobile, otp) => async () => {
  const data = {
    PhoneCountryId: 2,
    PhoneNo: mobile,
    Otp: otp,
  }
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(`${API_URL}/ChangePhoneno`, data, {
      headers: headers1(),
    })

    if (response.data.StatusCode === "0" || response.data.StatusCode === "08") {
      return [response, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}
export const profileChangePhoneNo = (mobile, otp = "") => async () => {
  const data = {
    PhoneCountryId: 2,
    PhoneNo: mobile,
    Otp: otp,
  }
  let response = {
    data: {
      Message: "",
      StatusCode: "",
    },
  }
  try {
    response = await axios.post(`${API_URL}/ChangePhoneno`, data, {
      headers: headers1(),
    })
    if (response.data.StatusCode === "0" || response.data.StatusCode === "08") {
      return [response, null]
    } else {
      return [response, response.data.Message]
    }
  } catch (error) {
    return [null, "something went wrong : " + error]
  }
}

export const getReason = () => {
  return dispatch => {
    axios
      .post(`${API_URL}/TransferObjective`, null, {
        headers: headers1(),
      })
      .then(function(response) {
        if (response.data.StatusCode === "0") {
          dispatch({
            type: loginConstant.REASON,
            payload: response.data.Items,
          })
        } else {
        }
      })
      .catch(function(error) {
        alert("something went wrong")
      })
  }
}

export const loadGoogleMaps = () => {
  if (!googleMapsPromise) {
    googleMapsPromise = new Promise((resolve, reject) => {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyBIQZeUTLh6uv6S0fjTNqcFW8ByHN5DEnI&libraries=places&callback=Function.prototype&language=en`,
        errorOrNull => {
          if (errorOrNull) {
            reject(errorOrNull)
          }
          resolve(window.google.maps)
        }
      )
    })
  }

  return googleMapsPromise
}
//  export const  editAddress= (data)=>{
//     const values = {
//         "MemberId": parseInt(localStorage.getItem('smartway_user_info')),
//         "AusUnitNumber":data.unit,
//         "AusStreetNumber":data.street_number,
//         "AusStreetName":data.street_name,
//         "AusStreetType":data.street_type,
//         "AusSuburb":data.suburb,
//         "AusState":data.state,
//         "AusPostalCode":data.postcode,
//         "AusCountry":data.country
//     }

//     return axios.post(`${API_URL}/EditAddress`,values
//         ,{
//             headers:headers
//         })

// }
