import React, { useEffect, useState } from "react"
import { useWindowWidth } from "src/helpers"
import { Link, navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import dropdown from "src/images/icons/dropdown.png"
import global from "src/images/icons/global.png"
import translate from "src/helpers/language"
import NoProfile from "src/images/icons/noProfile.png"
import { Icon, Menu } from "antd"
import { getAdvertize, getAdvertizeWeb } from "../../state/action/currency"
import crossIcon from "src/images/icons/cross.png"
import { getProfile3 } from "../../state/action/profile"

const { SubMenu } = Menu
const Thai = "TH",
  English = "EN"

const Navigation = ({ homepage }) => {
  const allState = useSelector(state => state)
  const viewportWidth = useWindowWidth()
  const getProfileStatus = useSelector(state => state.profile?.profile)
  const mainImage = useSelector(state => state.profile?.mainImage)
  const [currentPage, setCurrentPage] = useState("home")
  const [smartwayAuthCheck, setSmartwayAuthCheck] = useState(false)
  const [imageProfile, setImageProfile] = useState("")
  const [currentLang, setCurrentLang] = useState(English)
  const dispatch = useDispatch()
  const [showAd, setShowAd] = useState(false)
  const [adImage, setAdImage] = useState("")
  const fetchAdvertisement = async () => {
    let advertize = await dispatch(getAdvertize)
    const webImg = await dispatch(getAdvertizeWeb)
    if (viewportWidth > 992) {
      if (webImg?.data?.Object) {
        setAdImage(webImg?.data?.Object)
      } else {
        setAdImage(advertize)
      }
    } else {
      if (advertize) {
        setAdImage(advertize)
      } else {
        setAdImage(webImg?.data?.Object)
      }
    }
    if (homepage) {
      setShowAd(true)
    }
  }

  useEffect(() => {
    if (mainImage) {
      setImageProfile(mainImage)
    } else {
      setImageProfile(getProfileStatus?.ProfileHyperLink)
    }
  }, [mainImage, getProfileStatus])

  useEffect(() => {
    ;(async () => {
      if (typeof window !== "undefined") {
        if (!!localStorage.getItem("smartway_auth")) {
          setSmartwayAuthCheck(true)
          const profileUpdate = async () => await dispatch(getProfile3())
          if (profileUpdate.StatusCode == "01") {
            if (localStorage.getItem("smartway_auth")) {
              window.location.href = "/login?session=expired"
              // console.log('DEBUG: "/login?session=expired"');
            }
            setSmartwayAuthCheck(false)
          }
        } else {
          setSmartwayAuthCheck(false)
        }
      }
      let currLang = English
      if (typeof window !== "undefined") {
        currLang =
          typeof window !== "undefined" && window.location.href.includes("/th")
            ? Thai
            : English
      } else {
        currLang = localStorage.getItem("lang") || English
      }

      localStorage.setItem("lang", currLang)
      setCurrentLang(currLang)
      if (homepage) {
        await fetchAdvertisement()
      }
    })()
  }, [])

  const getPathEnOrTh = lang => {
    if (typeof window !== "undefined") {
      if (window.location.pathname === "/") {
        return lang === Thai ? "/th" : "/en"
      }
      if (window.location.pathname === "/en" && lang === Thai) {
        return window.location.pathname.replace("/en", "/th")
      } else if (window.location.pathname === "/th" && lang === English) {
        return window.location.pathname.replace("/th", "/en")
      }
      if (lang === Thai) {
        return window.location.pathname.replace("/en/", "/th/")
      } else {
        return window.location.pathname.replace("/th/", "/en/")
      }
    } else {
      return lang === Thai ? "/th" : "/en"
    }
  }

  const handleClick = e => {
    setCurrentPage(e.key)
  }

  if (typeof window === `undefined`) {
    return <></>
  }

  const handleLogout = () => {
    localStorage.removeItem("smartway_auth")
    setSmartwayAuthCheck(false)
    navigate(translate("/en/login/", "/th/login/"))
  }

  return (
    <>
      <Menu
        className="navigation nav-desktop"
        selectedKeys={[currentPage]}
        mode="horizontal"
      >
        <SubMenu
          key="home"
          title={
            <span>
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/about-us" : "/en/about-us"}
              >
                {translate("About Us", "เกี่ยวกับ")}
              </Link>
              <img src={dropdown} className="icon--dropdown"></img>
            </span>
          }
          style={{ float: "left" }}
        >
          <Menu.Item key="contact">
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/contact" : "/en/contact"}
            >
              {translate("Contact", "ติดต่อ")}
            </Link>
          </Menu.Item>
          <Menu.Item key="news">
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/news" : "/en/news"}
            >
              {translate("News", "ข่าวสาร")}
            </Link>
          </Menu.Item>
          <Menu.Item key="security">
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/security" : "/en/security"}
            >
              {translate("Security", "ความปลอดภัย")}
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="moneyTransfer" style={{ float: "left" }}>
          <Link
            className="navigation__item"
            to={
              currentLang === Thai ? "/th/money_transfer" : "/en/money_transfer"
            }
          >
            {translate("Money Transfer", "โอนเงิน")}
          </Link>
        </Menu.Item>
        <Menu.Item key="blogs" style={{ float: "left" }}>
          <Link
            className="navigation__item"
            to={currentLang === Thai ? "/th/blog" : "/en/blog"}
          >
            {translate("Blogs", "บล็อก")}
          </Link>
        </Menu.Item>
        <Menu.Item key="helpFaq" style={{ float: "left" }}>
          <Link
            className="navigation__item"
            to={currentLang === Thai ? "/th/faqs" : "/en/faqs"}
          >
            {translate("FAQs", "คำถามที่พบบ่อย")}
          </Link>
        </Menu.Item>
        {smartwayAuthCheck && (
          <Menu.Item key="inbox" style={{ float: "left" }}>
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/inbox" : "/en/inbox"}
            >
              {translate("Inbox", "ข้อความ")}
            </Link>
          </Menu.Item>
        )}
        {smartwayAuthCheck && (
          <Menu.Item key="profile" style={{ float: "left" }}>
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/profile" : "/en/profile"}
            >
              {translate("Profile", "บัญชี")}
            </Link>
          </Menu.Item>
        )}
        {smartwayAuthCheck && (
          <Menu.Item
            className="right__menu right__menu--register"
            key="register"
            style={{ float: "right" }}
          >
            <span
              onClick={() => {
                handleLogout()
              }}
              className="navigation__item navigation__item--register"
            >
              {translate("Logout", "ออกจากระบบ")}
            </span>
          </Menu.Item>
        )}

        {!smartwayAuthCheck && (
          <>
            <Menu.Item
              className="right__menu right__menu--register"
              key="register"
              style={{ float: "right" }}
            >
              <Link
                className="navigation__item navigation__item--register"
                to={currentLang === Thai ? "/th/register" : "/en/register"}
              >
                {" "}
                {translate("Register", "ลงทะเบียน")}
              </Link>
            </Menu.Item>
            <Menu.Item
              className="right__menu"
              key="login"
              style={{ float: "right" }}
            >
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/login" : "/en/login"}
              >
                {translate("Login", "เข้าสู่ระบบ")}
              </Link>
            </Menu.Item>
          </>
        )}

        <SubMenu
          key="language"
          title={
            <span>
              <img src={global} className="icon--global"></img>
              <a className="navigation__item">{currentLang}</a>
              <img src={dropdown} className="icon--dropdown"></img>
            </span>
          }
          style={{ float: "right" }}
          className="right__menu right__menu--language"
        >
          <Menu.Item key="thai">
            <Link
              onClick={() => {
                setCurrentLang(Thai)
              }}
              className="navigation__item navigation__item--language"
              to={getPathEnOrTh(Thai)}
            >
              {Thai}
            </Link>
          </Menu.Item>
          <Menu.Item key="eng">
            <Link
              onClick={() => {
                setCurrentLang(English)
              }}
              className="navigation__item navigation__item--language"
              to={getPathEnOrTh(English)}
            >
              {English}
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          className="right__menu"
          key="register"
          style={{ float: "right" }}
          onClick={() => setShowAd(!showAd)}
        >
          <div
            style={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              display: "inline-block",
              verticalAlign: "middle",
              position: "relative",
              backgroundColor: "#FFD747",
            }}
          >
            <span
              style={{
                color: "#45578C",
                fontWeight: "bold",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "21px",
                paddingLeft: "2px",
                marginTop: "-1px",
                fontFamily: "monospace",
              }}
            >
              i
            </span>
          </div>
        </Menu.Item>
      </Menu>

      <Menu defaultSelectedKeys={["1"]} mode="inline" className={"nav-mobile"}>
        <SubMenu
          key="mobileMenu"
          title={<Icon type="menu" />}
          style={{
            width: "100%",
          }}
        >
          <SubMenu
            key="mobileAboutus"
            title={
              <span>
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/about-us" : "/en/about-us"}
                style={{
                  top: "auto",
                }}
              >
                {translate("About Us", "เกี่ยวกับ")}
              </Link>
              {/*<img src={dropdown} className="icon--dropdown"></img>*/}
            </span>}
            // onTitleClick={() => {
            //   navigate(currentLang === Thai ? "/th/about-us" : "/en/about-us")
            // }}
          >
            <Menu.Item
              key="mobileAontact"
              onClick={() => {
                navigate(currentLang === Thai ? "/th/contact" : "/en/contact")
              }}
            >
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/contact" : "/en/contact"}
              >
                {translate("Contact", "ติดต่อ")}
              </Link>
            </Menu.Item>
            <Menu.Item
              key="mobileNews"
              onClick={() => {
                navigate(currentLang === Thai ? "/th/news" : "/en/news")
              }}
            >
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/news" : "/en/news"}
              >
                {translate("News", "ข่าวสาร")}
              </Link>
            </Menu.Item>
            <Menu.Item
              key="mobileSecurity"
              onClick={() => {
                navigate(currentLang === Thai ? "/th/security" : "/en/security")
              }}
            >
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/security" : "/en/security"}
              >
                {translate("Security", "ความปลอดภัย")}
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="mobileMoneyTransfer"
            onClick={() => {
              navigate(
                currentLang === Thai
                  ? "/th/money_transfer"
                  : "/en/money_transfer"
              )
            }}
          >
            <Link
              className="navigation__item"
              to={
                currentLang === Thai
                  ? "/th/money_transfer"
                  : "/en/money_transfer"
              }
            >
              {translate("Money Transfer", "โอนเงิน")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="mobileBlogs"
            onClick={() => {
              navigate(currentLang === Thai ? "/th/blog" : "/en/blog")
            }}
          >
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/blog" : "/en/blog"}
            >
              {translate("Blogs", "บล็อก")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="mobileHelpFaq"
            onClick={() => {
              navigate(currentLang === Thai ? "/th/faqs" : "/en/faqs")
            }}
          >
            <Link
              className="navigation__item"
              to={currentLang === Thai ? "/th/faqs" : "/en/faqs"}
            >
              Help &amp; FAQ
            </Link>
          </Menu.Item>
          {smartwayAuthCheck && (
            <Menu.Item
              key="mobileInbox"
              onClick={() => {
                navigate(currentLang === Thai ? "/th/inbox" : "/en/inbox")
              }}
            >
              <Link
                className="navigation__item"
                to={currentLang === Thai ? "/th/inbox" : "/en/inbox"}
              >
                {translate("Inbox", "ข้อความ")}
              </Link>
            </Menu.Item>
          )}
          <SubMenu
            key="mobileLanguage"
            title={
              <span style={{ verticalAlign: "middle" }}>
                <img
                  src={global}
                  className="icon--global"
                  style={{ verticalAlign: "middle", width: "10px" }}
                ></img>
                <a style={{ verticalAlign: "middle" }}>{currentLang}</a>
              </span>
            }
            className="right__menu right__menu--language"
          >
            <Menu.Item key="mobileThai">
              <Link
                className="navigation__item--language"
                to={getPathEnOrTh(Thai)}
              >
                {Thai}
              </Link>
            </Menu.Item>
            <Menu.Item key="mobileEnglish">
              <Link
                className="navigation__item--language"
                to={getPathEnOrTh(English)}
              >
                {English}
              </Link>
            </Menu.Item>
          </SubMenu>

          {smartwayAuthCheck && (
            <Menu.Item
              className="right__menu right__menu--profile-image"
              key="mobile-profile"
              style={{ float: "right" }}
            >
              <Link
                className="navigation__item navigation__item"
                onClick={() => {
                  navigate(currentLang === Thai ? "/th/profile" : "/en/profile")
                }}
                to={currentLang === Thai ? "/th/profile" : "/en/profile"}
              >
                <div>
                  <img
                    src={imageProfile || NoProfile}
                    style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "45px",
                    }}
                  ></img>
                </div>
                <p>
                  {translate("Welcome", "ยินดีต้อนรับ")},{" "}
                  {translate(
                    getProfileStatus?.FirstName,
                    getProfileStatus?.FirstName
                  )}
                </p>
              </Link>
            </Menu.Item>
          )}

          {smartwayAuthCheck && (
            <Menu.Item
              className="right__menu right__menu--register"
              key="register"
              style={{ float: "right" }}
            >
              <span
                onClick={() => {
                  handleLogout()
                }}
                className="navigation__item navigation__item--register"
              >
                {translate("Logout", "ออกจากระบบ")}
              </span>
            </Menu.Item>
          )}

          {!smartwayAuthCheck && (
            <Menu.Item className="right__menu" key="login">
              <Link to={currentLang === Thai ? "/th/login" : "/en/login"}>
                {translate("Login", "เข้าสู่ระบบ")}
              </Link>
            </Menu.Item>
          )}
          {!smartwayAuthCheck && (
            <Menu.Item
              className="right__menu right__menu--register"
              key="register"
            >
              <Link
                className="navigation__item--register"
                to={currentLang === Thai ? "/th/register" : "/en/register"}
              >
                {translate("Register", "ลงทะเบียน")}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item
            className="right__menu"
            key="adMunus"
            style={{ float: "right" }}
            onClick={() => setShowAd(!showAd)}
          >
            <div
              style={{
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "inline-block",
                verticalAlign: "middle",
                position: "relative",
                backgroundColor: "#FFD747",
              }}
            >
              <span
                style={{
                  color: "#45578C",
                  fontWeight: "bold",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "21px",
                  paddingLeft: "2px",
                  marginTop: "-1px",
                  fontFamily: "monospace",
                }}
              >
                i
              </span>
            </div>
          </Menu.Item>
        </SubMenu>
      </Menu>
      {showAd && (
        <div className="transfer__modal app-modal">
          <div className="transfer__modal--select-account app-body">
            <div
              className="transfer__modal--cross"
              onClick={() => {
                setShowAd(false)
              }}
              style={{ top: "10px", right: "10px" }}
            >
              <img
                src={crossIcon}
                className="transfer__modal--cross-img"
                style={{ width: "15px" }}
              />
            </div>
            <div className="account__select account__select--exist app-image-body">
              <img src={adImage}></img>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Navigation
