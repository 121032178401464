import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import Resizer from "react-image-file-resizer"
import { navigate } from "gatsby"
import "antd/dist/antd.css"
import {
  editAddress,
  getProfile,
  getProfile3,
  loadGoogleMaps,
} from "../../state/action/profile"
import {
  editPersonal,
  mobileOtp,
  searchaddressplaces,
  submitEditinfo,
  submitUploadInfo,
  uploadFind,
} from "../../state/action/login"
import {
  allSalary,
  ConvenientTimeToContact,
  FrequencyOfTransfer,
  getGender,
  nationalityAll,
  Occupation,
  titleAll,
} from "../../state/action/currency"
import translate from "src/helpers/language"
import classNames from "classnames"
import Swal from "sweetalert2"
import * as moment from "moment"
import { ApiObjectDocumentStruct } from "../../state/struct/document"
import {
  TypeAustraliaPhotoCard,
  TypeDriverLicense,
  TypePassport,
  TypeUtilityBill,
} from "../../state/constants/documentType"
import DateComponent from "../../components/dateComponent"

var globalvariable = {}
let googleMap

const RegistrationTwo = () => {
  const uploadImges = useRef()
  const uploadImges1 = useRef()
  const uploadImges2 = useRef()
  const uploadImges3 = useRef()
  const addressoption = useRef()

  const opt1 = useRef()
  const opt2 = useRef()
  const opt3 = useRef()
  const opt4 = useRef()
  const opt5 = useRef()
  const opt6 = useRef()

  const [uploadImg, setUploadImg] = useState("")
  const [uploadedImg, setUploadedImg] = useState(false)
  const [uploadImg1, setUploadImg1] = useState("")
  const [uploadedImg1, setUploadedImg1] = useState(false)
  const [uploadImg2, setUploadImg2] = useState("")
  const [uploadedImg2, setUploadedImg2] = useState(false)
  const [uploadImg3, setUploadImg3] = useState("")
  const [uploadedImg3, setUploadedImg3] = useState(false)
  const [addressBar, showAddressBar] = useState(true)
  const [transferModalNormal, showTransferModalNormal] = useState(false)
  const [transferModalGoogle, showTransferModalGoogle] = useState(false)
  const [accountDetails, setAccountDetails] = useState(true)
  const [address, setAddress] = useState(false)
  const [identification, setIdentification] = useState(false)
  const [personalDetails, setPersonalDetails] = useState(false)
  const [errorPlace, setErrorPlace] = useState(false)
  const [showPlaceOptions, setShowPlaceOptions] = useState(false)
  const [complete, setComplete] = useState(false)
  const [regNavigation, setRegNavigation] = useState(false)
  const [step1active, setStep1active] = useState(true)
  const [step2active, setStep2active] = useState(false)
  const [step3active, setStep3active] = useState(false)
  const [step4active, setStep4active] = useState(false)
  const [line1active, setLine1active] = useState(false)
  const [line2active, setLine2active] = useState(false)
  const [line3active, setLine3active] = useState(false)
  const [openModel, setOpenModel] = useState(false)
  const [searchAddress, setSearchAddress] = useState("")
  const [options, setOptions] = useState([{ value: "a" }])
  const [successMessage, showSuccessMessage] = useState(false)
  const [poiValue, setPoiValue] = useState("")
  const [poaValue, setPoaValue] = useState("")
  const [poiFind, setPoiFind] = useState("Type of Document")
  const [poaFind, setPoaFind] = useState("Type of Document")
  const [previousUpload, setPreviousUpload] = useState(false)
  const [previousUploadPoi, setPreviousUploadPoi] = useState(false)
  const [previousUploadPoa, setPreviousUploadPoa] = useState(false)
  const [poiId, setPoiId] = useState()
  const [poaId, setPoaId] = useState()
  const [poiIdChange, setPoiIdChange] = useState(false)
  const [poaIdChange, setPoaIdChange] = useState(false)
  const [placesid, setplacesid] = useState([])
  const dispatch = useDispatch()
  const [error, setError] = useState("")
  const englishCheck = /^[A-Za-z0-9@%+,><|'"!#$\-*/\()_.~ ]*$/
  const englishCheckAZ = /^[A-Za-z]*$/

  const allProfiles = useSelector(state => state.currency)
  const getProfileStatus = useSelector(state => state.profile)

  const [otpConfirm, setOtpConfirm] = useState(false)
  const [setFirstOtpConfirm, firstOtpConfirm] = useState(false)
  const pacInputRef = useRef()

  const [initialValues, setInitialValues] = useState({
    username: getProfileStatus?.profile?.Username,
    password: "",
    email: getProfileStatus?.profile?.Email,
    mobile: getProfileStatus?.profile?.PhoneNo,
    title: getProfileStatus?.profile?.TitleId,
    firstName: getProfileStatus?.profile?.FirstName,
    middleName: getProfileStatus?.profile?.MiddleName,
    lastName: getProfileStatus?.profile?.LastName,
    dob: getProfileStatus?.profile?.BirthDateISO,
    nationality: getProfileStatus?.profile?.NationalityId,
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  })

  const [addressInitialValues, setAddressInitialValues] = useState({
    unit: getProfileStatus?.profile?.AusUnitNumber || "",
    street_number: getProfileStatus?.profile?.AusStreetNumber || "",
    street_name: getProfileStatus?.profile?.AusStreetName || "",
    street_type: getProfileStatus?.profile?.AusStreetType || "",
    suburb: getProfileStatus?.profile?.AusSuburb || "",
    state: getProfileStatus?.profile?.AusState || "",
    postcode: getProfileStatus?.profile?.AusPostalCode || "",
    country: "AU",
    address: "",
  })

  const [uploadInitialValues, setUploadInitialValues] = useState({
    MemberDocumentTypeId_poi: poiId,
    DocumentNo_poi: getProfileStatus?.profile?.PassportNo,
    DocumentExpireDateISO_poi:
      getProfileStatus?.profile?.PassportNoExpireDateISO,
    DocumentIssueDateISO_poi: getProfileStatus?.profile?.DocumentIssueDateISO,
    DocumentIssue_poi: getProfileStatus?.profile?.DocumentStateOrCountry,
    DocumentBase64_poi: getProfileStatus?.profile?.PassportHyperlink,
    DocumentName_poi: "",

    MemberDocumentTypeId_poa: poaId,
    DocumentNo_poa: getProfileStatus?.profile?.DrivingLicenseTH,
    DocumentExpireDateISO_poa:
      getProfileStatus?.profile?.DrivingLicenseTHExpireDateISO,
    DocumentIssuer_poa: getProfileStatus?.profile?.DrivingLicenseThIssuer,
    DocumentBase64_poa: getProfileStatus?.profile?.DriverLicenseThHyperlink,
    DocumentName_poa: "",
    DocumentBackBase64_poa:
      getProfileStatus?.profile?.DriverLicenseThBackHyperlink,
    DocumentBackName_poa: "",
  })

  function initUploadDocumentFromApi(uploadInfo = ApiObjectDocumentStruct) {
    let docs = uploadInfo?.Object?.Documents
    if (!docs) return
    setUploadInitialValues({
      MemberDocumentTypeId_poi: docs[0]?.MemberDocumentTypeId,
      DocumentNo_poi: docs[0]?.DocumentNo,
      DocumentExpireDateISO_poi: docs[0]?.DocumentExpireDateISO
        ? moment(docs[0]?.DocumentExpireDateISO).format("YYYY-MM-DD")
        : null,
      DocumentIssue_poi: docs[0]?.DocumentIssuer,
      DocumentBase64_poi: docs[0]?.DocumentHyperlink,
      DocumentBackBase64_poi: docs[0]?.DocumentBackHyperlink,
      DocumentIssueDateISO_poi: docs[0]?.DocumentIssueDateISO
        ? moment(docs[0]?.DocumentIssueDateISO).format("YYYY-MM-DD")
        : null,

      MemberDocumentTypeId_poa: docs[1]?.MemberDocumentTypeId,
      DocumentNo_poa: docs[1]?.DocumentNo,
      DocumentExpireDateISO_poa: docs[1]?.DocumentExpireDateISO
        ? moment(docs[1]?.DocumentExpireDateISO).format("YYYY-MM-DD")
        : null,
      DocumentIssuer_poa: docs[1]?.DocumentIssuer,
      DocumentBase64_poa: docs[1]?.DocumentHyperlink,
      DocumentBackBase64_poa: docs[1]?.DocumentBackHyperlink,
      DocumentIssueDateISO_poa: docs[1]?.DocumentIssueDateISO
        ? moment(docs[1]?.DocumentIssueDateISO).format("YYYY-MM-DD")
        : null,
      DocumentState_poi: docs[0]?.DocumentStateOrCountry,
      DocumentState_poa: docs[1]?.DocumentStateOrCountry,
    })
  }

  function clearUploadPoi(oldValue) {
    setUploadInitialValues({
      ...oldValue,
      MemberDocumentTypeId_poi: 0,
      DocumentNo_poi: "",
      DocumentExpireDateISO_poi: "",
      DocumentIssueDateISO_poi: "",
      DocumentIssue_poi: "",
      DocumentBase64_poi: "",
      DocumentName_poi: "",
      DocumentBackBase64_poi: "",
      DocumentBackName_poi: "",
      DocumentState_poi: "",
    })
  }

  function clearUploadPoa(oldValue) {
    setUploadInitialValues({
      ...oldValue,
      MemberDocumentTypeId_poa: 0,
      DocumentNo_poa: "",
      DocumentExpireDateISO_poa: "",
      DocumentIssueDateISO_poa: "",
      DocumentIssuer_poa: "",
      DocumentBase64_poa: "",
      DocumentName_poa: "",
      DocumentBackBase64_poa: "",
      DocumentBackName_poa: "",
      DocumentState_poa: "",
    })
  }

  useEffect(() => {
    console.log(
      "DEBUG: getProfileStatus?.profile?.BirthDateISO",
      getProfileStatus?.profile?.BirthDateISO
    )
    console.log(
      "DEBUG: date",
      new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
        .toISOString()
        .split("T")[0]
    )
    setInitialValues({
      username: getProfileStatus?.profile?.Username,
      password: "",
      email: getProfileStatus?.profile?.Email,
      mobile: getProfileStatus?.profile?.PhoneNo,
      title: getProfileStatus?.profile?.TitleId,
      firstName: getProfileStatus?.profile?.FirstName,
      middleName: getProfileStatus?.profile?.MiddleName,
      lastName: getProfileStatus?.profile?.LastName,
      dob: getProfileStatus?.profile?.BirthDateISO,
      nationality: getProfileStatus?.profile?.NationalityId,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    })
  }, [accountDetails])

  useEffect(() => {
    setAddressInitialValues({
      unit: getProfileStatus?.profile?.AusUnitNumber || "",
      street_number: getProfileStatus?.profile?.AusStreetNumber || "",
      street_name: getProfileStatus?.profile?.AusStreetName || "",
      street_type: getProfileStatus?.profile?.AusStreetType || "",
      suburb: getProfileStatus?.profile?.AusSuburb || "",
      state: getProfileStatus?.profile?.AusState || "",
      postcode: getProfileStatus?.profile?.AusPostalCode || "",
      country: "AU",
      address: "",
    })
  }, [address])

  useEffect(() => {
    ;(async () => {
      const uploadInfo = await dispatch(uploadFind)

      if (
        uploadInfo.data.Object.Documents.length != 0 &&
        previousUpload == true
      ) {
        setPreviousUpload(true)
        setPreviousUploadPoi(true)
        setPreviousUploadPoa(true)
      } else {
        setPreviousUpload(false)
        setPreviousUploadPoi(false)
        setPreviousUploadPoa(false)
      }
      if (uploadInfo?.data?.Object?.Documents[0]?.MemberDocumentTypeId === 1) {
        setPoiFind("Passport")
        setPoiValue(TypePassport)
      } else if (
        uploadInfo?.data?.Object?.Documents[0]?.MemberDocumentTypeId === 4
      ) {
        setPoiFind("Driver License")
        setPoiValue(TypeDriverLicense)
      } else if (
        uploadInfo?.data?.Object?.Documents[0]?.MemberDocumentTypeId === 6
      ) {
        setPoiFind("Australia Photo Card")
        setPoiValue(TypeAustraliaPhotoCard)
      }

      if (uploadInfo?.data?.Object?.Documents[1]?.MemberDocumentTypeId === 4) {
        setPoaFind("Driver License")
        setPoaValue(TypeDriverLicense)
      } else if (
        uploadInfo?.data?.Object?.Documents[1]?.MemberDocumentTypeId === 6
      ) {
        setPoaFind("Australia Photo Card")
        setPoaValue(TypeAustraliaPhotoCard)
      } else if (
        uploadInfo?.data?.Object?.Documents[1]?.MemberDocumentTypeId === 2
      ) {
        setPoaFind("Utility Bill")
        setPoaValue(TypeUtilityBill)
      }
      initUploadDocumentFromApi(uploadInfo?.data)

      setUploadImg(uploadInfo?.data?.Object?.Documents[0]?.DocumentHyperlink)
      setUploadImg3(
        uploadInfo?.data?.Object?.Documents[0]?.DocumentBackHyperlink
      )
      setUploadImg1(uploadInfo?.data?.Object?.Documents[1]?.DocumentHyperlink)
      setUploadImg2(
        uploadInfo?.data?.Object?.Documents[1]?.DocumentBackHyperlink
      )
    })()
  }, [identification])

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      !!localStorage.getItem("smartway_auth")
    ) {
      if (
        getProfileStatus.profile.MemberStatusId === 1 &&
        getProfileStatus.profile.MemberProfileStatusId === null
      ) {
      } else if (
        getProfileStatus.profile.MemberStatusId === 1 &&
        getProfileStatus.profile.MemberProfileStatusId === 4
      ) {
      } else if (
        getProfileStatus.profile.MemberStatusId === 1 &&
        getProfileStatus.profile.MemberProfileStatusId === 5
      ) {
      } else if (
        getProfileStatus.profile.MemberStatusId === 1 &&
        getProfileStatus.profile.MemberProfileStatusId === 1
      ) {
      } else if (
        getProfileStatus.profile.MemberStatusId === 1 &&
        getProfileStatus.profile.MemberProfileStatusId === 2
      ) {
      } else if (!getProfileStatus.loader) {
        navigate("/")
      }
    } else {
      navigate(
        typeof window !== "undefined" && window.location.href.includes("/th")
          ? "/th/register"
          : "/en/register"
      )
    }
    setUploadImg(getProfileStatus?.profile?.PassportHyperlink)
    setUploadImg1(getProfileStatus?.profile?.DriverLicenseThHyperlink)
    setUploadImg2(getProfileStatus?.profile?.DriverLicenseThBackHyperlink)
  }, [getProfileStatus])

  useEffect(() => {
    setInitialValues({
      username: getProfileStatus?.profile?.Username,
      password: "",
      email: getProfileStatus?.profile?.Email,
      mobile: getProfileStatus?.profile?.PhoneNo,
      title: getProfileStatus?.profile?.TitleId,
      firstName: getProfileStatus?.profile?.FirstName,
      middleName: getProfileStatus?.profile?.MiddleName,
      lastName: getProfileStatus?.profile?.LastName,
      dob: getProfileStatus?.profile?.BirthDateISO,
      nationality: getProfileStatus?.profile?.NationalityId,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    })
    setAddressInitialValues({
      unit: getProfileStatus?.profile?.AusUnitNumber || "",
      street_number: getProfileStatus?.profile?.AusStreetNumber || "",
      street_name: getProfileStatus?.profile?.AusStreetName || "",
      street_type: getProfileStatus?.profile?.AusStreetType || "",
      suburb: getProfileStatus?.profile?.AusSuburb || "",
      state: getProfileStatus?.profile?.AusState || "",
      postcode: getProfileStatus?.profile?.AusPostalCode || "",
      country: "AU",
      address: "",
    })
    let registerStep = getProfileStatus?.profile?.RegisterStep
    // let registerStep = "UploadDocument"
    if (!regNavigation) {
      if (registerStep === "EditAccountInfo") {
        setAccountDetails(true)
        setAddress(false)
        setIdentification(false)
        setPersonalDetails(false)

        setStep1active(true)
        setStep2active(false)
        setStep3active(false)
        setStep4active(false)
        setLine1active(false)
        setLine2active(false)
        setLine3active(false)
      } else if (registerStep === "EditAddress") {
        setAccountDetails(false)
        setAddress(true)
        setIdentification(false)
        setPersonalDetails(false)

        setStep1active(true)
        setStep2active(true)
        setStep3active(false)
        setStep4active(false)
        setLine1active(true)
        setLine2active(false)
        setLine3active(false)
      } else if (registerStep === "UploadDocument") {
        setAccountDetails(false)
        setAddress(false)
        setIdentification(true)
        setPersonalDetails(false)

        setStep1active(true)
        setStep2active(true)
        setStep3active(true)
        setStep4active(false)
        setLine1active(true)
        setLine2active(true)
        setLine3active(false)
      } else if (registerStep === "EditPersonalDetail") {
        setAccountDetails(false)
        setAddress(false)
        setIdentification(false)
        setPersonalDetails(true)

        setStep1active(true)
        setStep2active(true)
        setStep3active(true)
        setStep4active(true)
        setLine1active(true)
        setLine2active(true)
        setLine3active(true)
      }
    } else {
      setRegNavigation(false)
    }
  }, [getProfileStatus])

  useEffect(() => {
    if (!googleMap) {
      setTimeout(() => {
        loadGoogleMaps().then(google => {
          googleMap = google
        })
      }, 1000)
    }
    return () => {}
  }, [])
  useEffect(() => {
    let intervalIndex = setInterval(() => {
      if (googleMap && pacInputRef && pacInputRef.current) {
        const autocomplete = new googleMap.places.Autocomplete(
          pacInputRef.current,
          {
            componentRestrictions: { country: "au" },
          }
        )
        if (autocomplete) {
          clearInterval(intervalIndex)
        }
        let addressForm = {
          unit: "",
          street_number: "",
          street_name: "",
          street_type: "",
          suburb: "",
          state: "",
          postcode: "",
          country: "AU",
          address: "",
        }
        const geocoder = new googleMap.Geocoder()
        autocomplete.addListener("place_changed", () => {
          // setAddress(false)
          const place = autocomplete.getPlace()
          if (!place.place_id) {
            return
          }
          geocoder
            .geocode({ placeId: place.place_id })
            .then(({ results }) => {
              let p = results[0]
              for (const component of p.address_components) {
                const componentType = component.types[0]
                let val = component[componentType]
                switch (componentType) {
                  case "street_number": {
                    addressForm.street_number = component.short_name
                    break
                  }
                  case "route": {
                    // addressForm.street_name = component.short_name;
                    let temp = component.short_name.split(" ")
                    if (temp.length > 1) {
                      let streetName = temp[0]
                      for (let j = 1; j < temp.length - 1; j++)
                        streetName += " " + temp[j]
                      addressForm.street_name = streetName
                      addressForm.street_type = temp[temp.length - 1]
                    } else {
                      // document.getElementById("AusStreetName").value = val;// ถนน
                      addressForm.street_name = component.short_name
                    }
                    break
                  }
                  case "postal_code": {
                    addressForm.postcode = component.short_name
                    break
                  }
                  case "locality":
                    addressForm.suburb = component.short_name
                    break
                  case "administrative_area_level_1": {
                    addressForm.state = component.short_name
                    break
                  }
                  case "country":
                    addressForm.country = component.short_name
                    break
                }
              }
              setAddressInitialValues(addressForm)
              // setAddress(true)
            })
            .catch(e => window.alert("Geocoder failed due to: " + e))
        })
      }
    }, 500)
  }, [pacInputRef.current, googleMap, address])

  function setUploadDocument(
    values = {
      MemberDocumentTypeId_poi: 0,
      DocumentNo_poi: "",
      DocumentExpireDateISO_poi: "",
      DocumentIssue_poi: "",
      DocumentBase64_poi: "",
      DocumentBackBase64_poi: "",
      MemberDocumentTypeId_poa: 0,
      DocumentNo_poa: "",
      DocumentExpireDateISO_poa: "",
      DocumentIssuer_poa: "",
      DocumentBase64_poa: "",
      DocumentBackBase64_poa: "",
      DocumentIssueDateISO_poi: "",
      DocumentIssueDateISO_poa: "",
      DocumentState_poi: "",
      DocumentState_poa: "",
      DocumentName_poi: "",
      poidocumenttype: "",
      DocumentBackName_poi: "",
      DocumentIssue_poa: "",
      DocumentName_poa: "",
      poadocumenttype: "",
    },
    uploadCheckInfo = {
      data: {
        Object: {
          Documents: [
            {
              DocumentHyperlink: "",
            },
          ],
        },
      },
    }
  ) {
    try {
      function getTextBase64(text = "") {
        if (!text) return text
        let sp = text.split("base64,")
        if (sp.length > 1) {
          return sp[1]
        } else {
          return null
        }
      }

      let doc1 = {
        MemberDocumentTypeId: values.MemberDocumentTypeId_poi,
        DocumentNo: values.DocumentNo_poi,
        DocumentExpireDateISO: values.DocumentExpireDateISO_poi,
        DocumentIssuer: values.DocumentIssue_poi,
        DocumentBase64: getTextBase64(values.DocumentBase64_poi),
        DocumentHyperlink:
          uploadCheckInfo?.data?.Object?.Documents[0]?.DocumentHyperlink,
        DocumentName: values.DocumentName_poi,
        DocumentBackBase64: getTextBase64(values.DocumentBackBase64_poi),
        DocumentBackHyperlink:
          uploadCheckInfo?.data?.Object?.Documents[0]?.DocumentBackHyperlink,
        DocumentBackName: values.DocumentBackName_poi,
        DocumentStateOrCountry: values.DocumentState_poi,
        DocumentIssueDateISO: values.DocumentIssueDateISO_poi,
      }
      if (
        doc1.MemberDocumentTypeId?.toString() === "1" ||
        doc1.MemberDocumentTypeId?.toString() === "2"
      ) {
        delete doc1.DocumentBackBase64
        delete doc1.DocumentBackHyperlink
      }
      let doc2 = {
        MemberDocumentTypeId: values.MemberDocumentTypeId_poa,
        DocumentNo: values.DocumentNo_poa,
        DocumentExpireDateISO: values.DocumentExpireDateISO_poa,
        DocumentIssuer: values.DocumentIssuer_poa,
        DocumentBase64: getTextBase64(values.DocumentBase64_poa),
        DocumentHyperlink:
          uploadCheckInfo?.data?.Object?.Documents[1]?.DocumentHyperlink,
        DocumentName: values.DocumentName_poa,
        DocumentBackBase64: getTextBase64(values.DocumentBackBase64_poa),
        DocumentBackHyperlink:
          uploadCheckInfo?.data?.Object?.Documents[1]?.DocumentBackHyperlink,
        DocumentBackName: values.DocumentBackName_poa,
        DocumentStateOrCountry: values.DocumentState_poa,
        DocumentIssueDateISO: values.DocumentIssueDateISO_poa,
      }
      if (
        doc2.MemberDocumentTypeId?.toString() === "1" ||
        doc2.MemberDocumentTypeId?.toString() === "2"
      ) {
        delete doc2.DocumentBackBase64
        delete doc2.DocumentBackHyperlink
      }

      let userData = {
        MemberId: parseInt(localStorage.getItem("smartway_user_info")),
        Documents: [],
      }
      if (doc1.MemberDocumentTypeId) userData.Documents.push(doc1)
      if (doc2.MemberDocumentTypeId) userData.Documents.push(doc2)
      return userData
    } catch (e) {
      console.error("DEBUG:  setUploadDocument", e)
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener("load", () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const showErrorPlace = () => {
    setErrorPlace(true)
  }

  const hideErrorPlace = () => {
    setErrorPlace(false)
  }

  const handleModalNormal = () => {
    showTransferModalNormal(!transferModalNormal)
  }

  const handleAddressBar = () => {
    showAddressBar(!addressBar)
  }

  const fileChangedHandler = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        "JPEG",
        100,
        0,
        uri => {
          resolve(uri)
        },
        "base64"
      )
    })

  useEffect(() => {
    dispatch(getGender())
    dispatch(Occupation())
    dispatch(allSalary())
    dispatch(FrequencyOfTransfer())
    dispatch(ConvenientTimeToContact())
    dispatch(titleAll())
    dispatch(nationalityAll())
    if (typeof window !== "undefined") {
      if (!!localStorage.getItem("smartway_auth")) {
        dispatch(getProfile3())
        dispatch(getProfile())
      }
    }
  }, [])

  const handleModalGoogle = () => {
    showTransferModalGoogle(!transferModalGoogle)
  }

  const handleCancelGoogleModal = () => {
    showTransferModalGoogle(!transferModalGoogle)
    showSuccessMessage(false)
  }

  const handleCancelNormalModal = () => {
    showTransferModalNormal(!transferModalNormal)
    showSuccessMessage(false)
  }

  const getAccountDetails = () => {
    setplacesid([])
    setStep2active(false)
    setStep1active(true)
    setLine1active(false)
    setLine2active(false)
    setIdentification(false)
    setAddress(false)
    setAccountDetails(true)
  }

  const getAddress = () => {
    setOpenModel(false)
    setStep2active(true)
    setLine1active(true)
    setStep3active(false)
    setLine2active(false)
    setIdentification(false)
    setAddress(true)
    setAccountDetails(false)
  }

  const getIdentification = () => {
    setStep4active(false)
    setLine3active(false)
    setStep3active(true)
    setLine2active(true)
    setIdentification(true)
    setPersonalDetails(false)
    setAddress(false)
  }

  const getPersonal = () => {
    setStep4active(true)
    setLine3active(true)
    setPersonalDetails(true)
    setIdentification(false)
  }
  const modalClasses = classNames("register-transfer__modal--select-account")

  const addressFillClasses = classNames("address-fill", {
    "address-fill-none": !showPlaceOptions,
  })

  const errorPlaceClasses = classNames("errorPlace", {
    errorPlaceShow: errorPlace,
  })

  const step1Classes = classNames("step", {
    "step-active": step1active,
  })

  const line1Classes = classNames("line", {
    "line-active": line1active,
  })

  const step2Classes = classNames("step", {
    "step-active": step2active,
  })

  const line2Classes = classNames("line", {
    "line-active": line2active,
  })

  const step3Classes = classNames("step", {
    "step-active": step3active,
  })

  const line3Classes = classNames("line", {
    "line-active": line3active,
  })

  const step4Classes = classNames("step", {
    "step-active": step4active,
  })

  const uploadClasses = classNames(
    "currency-input currency-group--transfer currency-input--upload",
    {
      "currency-input--upload-active": uploadedImg,
    }
  )

  const uploadClasses1 = classNames(
    "currency-input currency-group--transfer currency-input--upload",
    {
      "currency-input--upload-active": uploadedImg1,
    }
  )

  const uploadClasses2 = classNames(
    "currency-input currency-group--transfer currency-input--upload",
    {
      "currency-input--upload-active": uploadedImg2,
    }
  )

  const uploadWrapperClasses = classNames(
    "currency-group",
    uploadImg && "currency-group--imguploaded"
  )

  const uploadWrapperClasses1 = classNames(
    "currency-group",
    uploadImg1 && "currency-group--imguploaded"
  )

  const uploadWrapperClasses2 = classNames(
    "currency-group",
    uploadImg2 && "currency-group--imguploaded"
  )

  const uploadWrapperClasses3 = classNames(
    "currency-group",
    uploadImg3 && "currency-group--imguploaded"
  )

  // const nationalityRef = useRef(null);

  // const showNationalitySelect = () => {
  //   nationalityRef.current.focus()
  // }

  return (
    <>
      <section className="register-money-transfer">
        {!complete && (
          <>
            <div class="step--holder">
              <div className={step1Classes}></div>
              <div className={line1Classes}></div>
              <div className={step2Classes}></div>
              <div className={line2Classes}></div>
              <div className={step3Classes}></div>
              <div className={line3Classes}></div>
              <div className={step4Classes}></div>
            </div>
            <div className="step-name--holder">
              <div className="step-name">{translate("Account", "บัญชี")}</div>
              <div className="step-name">{translate("Address", "ที่อยู่")}</div>
              <div className="step-name">
                {translate("Identification", "ยืนยันตัวตน")}
              </div>
              <div className="step-name">
                {translate("Other Details", "รายละเอียดอื่น ๆ")}
              </div>
            </div>
          </>
        )}

        {accountDetails && (
          <>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={values => {
                const errors = {}
                if (!values.mobile) {
                  errors.mobile = "Required"
                } else if (String(values.mobile).length !== 9) {
                  errors.mobile = "Number should be like +61XXXXXXXXX"
                } else if (!englishCheck.test(values.mobile)) {
                  errors.mobile = "Please fill in English"
                }

                if (!englishCheck.test(values.middleName)) {
                  errors.middleName = "Please fill in English"
                }

                if (!values.title) {
                  errors.title = "Required"
                }
                if (!values.firstName) {
                  errors.firstName = "Required"
                } else if (!englishCheck.test(values.firstName)) {
                  errors.firstName = "Please fill in English"
                }
                // if (!values.middleName) {
                //   errors.middleName = "Required";
                // }
                if (!values.lastName) {
                  errors.lastName = "Required"
                } else if (!englishCheck.test(values.lastName)) {
                  errors.lastName = "Please fill in English"
                }
                if (!values.dob) {
                  errors.dob = "Required"
                }
                if (!values.nationality) {
                  errors.nationality = "Required"
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                values.username = getProfileStatus.profile?.Username
                values.email = getProfileStatus.profile?.Email

                setFieldValue("otp1", "")
                setFieldValue("otp2", "")
                setFieldValue("otp3", "")
                setFieldValue("otp4", "")
                setFieldValue("otp5", "")
                setFieldValue("otp6", "")

                if (
                  (getProfileStatus.profile?.IsComfirmPhoneNo == false &&
                    otpConfirm == false) ||
                  getProfileStatus?.profile?.PhoneNo != values.mobile
                ) {
                  let [response, error] = await dispatch(
                    mobileOtp(
                      parseInt(localStorage.getItem("smartway_user_info")),
                      values.mobile
                    )
                  )

                  if (error) {
                    Swal.fire({
                      text: error,
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonColor: "#3085d6",
                    })
                    return
                  }
                  Swal.close()
                  setOpenModel(true)
                } else if (getProfileStatus.profile?.IsComfirmPhoneNo == true) {
                  const accountInfoData2 = await dispatch(
                    submitEditinfo(values)
                  )
                  if (accountInfoData2.data.StatusCode === "0") {
                    setInitialValues(values)
                    getAddress()
                  } else {
                    Swal.fire(accountInfoData2.data.Message)
                  }
                } else {
                  const accountInfoData = await dispatch(submitEditinfo(values))
                  if (accountInfoData.data.StatusCode === "0") {
                    setInitialValues(values)
                    getAddress()
                  } else {
                    Swal.fire(accountInfoData.data.Message)
                  }
                }

                globalvariable = values
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div></div>
                  <h2>{translate("Account Details", "รายละเอียดบัญชี")}</h2>
                  <div className="currency-group currency-group--transfer">
                    <div className="currency-input">
                      <input
                        placeholder={translate("Username", "ชื่อผู้ใช้")}
                        type="text"
                        name="username"
                        //onChange={handleChange}
                        //onBlur={handleBlur}
                        value={getProfileStatus.profile?.UserName}
                        disabled
                      />
                    </div>
                    <div className="error-form">
                      {errors.username && touched.username && errors.username}
                    </div>
                  </div>

                  {/* <div className="currency-group currency-group--transfer">
                    <div className="currency-input">
                      <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        //onChange={handleChange}
                        //onBlur={handleBlur}
                        value={values.password}
                        disable

                      />
                    </div>
                    {errors.password && touched.password && errors.password}
                  </div> */}

                  <div className="currency-group currency-group--transfer">
                    <div className="currency-input">
                      <input
                        placeholder={translate("Email", "อีเมล")}
                        //type="email"
                        //name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={getProfileStatus.profile?.Email}
                        disabled
                      />
                    </div>
                    <div className="error-form">
                      {errors.email && touched.email && errors.email}
                    </div>
                  </div>

                  <div className="currency-group currency-group--transfer">
                    <div className="currency-input relative-currency-prefix mobile-input-class">
                      <input
                        placeholder={translate(
                          "Mobile Number (+614XXXXXXXX)",
                          "เบอร์โทรศัพท์ (+614XXXXXXXX)"
                        )}
                        type="number"
                        name="mobile"
                        onChange={e => {
                          handleChange(e)
                          // if(e.value != initialValues.mobile) {
                          //   setFirstOtpConfirm(false)
                          // }
                        }}
                        onBlur={handleBlur}
                        value={values.mobile}
                      />
                      <div className="prefix-mobile">+61</div>
                    </div>
                    <div className="error-form">
                      {errors.mobile && touched.mobile && errors.mobile}
                    </div>
                  </div>
                  <h2>
                    {translate(
                      "Personal Details",
                      "ข้อมูลส่วนตัว (โปรดระบุข้อมูลเป็นภาษาอังกฤษ)"
                    )}
                  </h2>
                  <div>
                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          name="title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                        >
                          <option selected value="">
                            {translate("Title", "คำนำหน้า")}
                          </option>
                          {allProfiles.title &&
                            allProfiles.title.map(occ => {
                              return (
                                <option value={occ.TitleId}>
                                  {occ.TitleName}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.title && touched.title && errors.title}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("First Name", "ชื่อจริง")}
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                      </div>
                      <div className="error-form">
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Middle Name", "ชื่อกลาง")}
                          type="text"
                          name="middleName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.middleName}
                        />
                      </div>
                      <div className="error-form">
                        {errors.middleName &&
                          touched.middleName &&
                          errors.middleName}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Last Name", "นามสกุล")}
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </div>
                      <div className="error-form">
                        {errors.lastName && touched.lastName && errors.lastName}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer currency-group--promo">
                      {/*<label htmlFor="inputDob">*/}
                      {/*  {translate("Date of Birth", "วันเกิด")}*/}
                      {/*</label>*/}
                      <div
                        className="currency-input currency-input--dateselector"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            width: "fit-content",
                            // backgroundColor:"red",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            left: "65px",
                            zIndex: "1",
                          }}
                        >
                          <label
                            htmlFor="inputDob"
                            style={{
                              color: "#214dce",
                              fontSize: "18px",
                              fontWeight: "400",
                              margin: 0,
                              paddingLeft: "10px",
                            }}
                          >
                            {values.dob
                              ? moment(values.dob).format("MM/DD/YYYY")
                              : translate("Date of Birth", "วันเกิด")}
                          </label>
                        </div>
                        <input
                          style={{
                            color: "white",
                            position: "absolute",
                            left: "65px",
                            backgroundColor: "transparent",
                            width: "fit-content",
                            textAlign: "center",
                            fontSize: "0px",
                          }}
                          id="inputDob"
                          placeholder={translate("Date of Birth", "วันเกิด")}
                          type="date"
                          name="dob"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.dob}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                      <div className="error-form">
                        {errors.dob && touched.dob && errors.dob}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          id="nationality"
                          name="nationality"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nationality}
                        >
                          <option selected value="">
                            {translate("Nationality", "สัญชาติ")}
                          </option>
                          {allProfiles.nationality &&
                            allProfiles.nationality.map(occ => {
                              return (
                                <option value={occ.NationalityId}>
                                  {occ.NationalityName}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.nationality &&
                          touched.nationality &&
                          errors.nationality}
                      </div>
                    </div>
                  </div>
                  {!!error && <div className="error-form">{error}</div>}
                  <div class="registration-link-holder">
                    <button
                      type="submit"
                      className="btn bold btn--yellow"
                      // onClick={getAddress}
                    >
                      {translate("Submit and Continue", "ส่งและดำเนินการต่อ")}
                    </button>
                  </div>
                  <Modal open={openModel} onClose={() => setOpenModel(false)}>
                    <h2>
                      {translate(
                        "Phone Number Verification",
                        "ยืนยันหมายเลขโทรศัพท์"
                      )}
                    </h2>
                    <p>
                      {translate(
                        "Enter 6 digits OTP sent to +61",
                        "กรอกรหัส OTP 6 หลัก ที่ส่งไปยังหมายเลข +61"
                      )}
                      {values.mobile}
                    </p>
                    <div className="otp-inputs">
                      <input
                        type="text"
                        maxlength="1"
                        style={{ width: "40px", fontSize: "24px" }}
                        name="otp1"
                        onChange={e => {
                          handleChange(e)
                        }}
                        onKeyUp={({ key }) => {
                          if (key === "Backspace") {
                          } else {
                            opt2.current.focus()
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.otp1}
                        ref={opt1}
                      />
                      <input
                        ref={opt2}
                        type="text"
                        maxlength="1"
                        style={{ width: "40px", fontSize: "24px" }}
                        name="otp2"
                        onChange={e => {
                          handleChange(e)
                        }}
                        onKeyUp={({ key }) => {
                          if (key === "Backspace" || key === "ArrowLeft") {
                            opt1.current.focus()
                            opt1.current.select()
                          } else {
                            opt3.current.focus()
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.otp2}
                      />
                      <input
                        ref={opt3}
                        type="text"
                        maxlength="1"
                        style={{ width: "40px", fontSize: "24px" }}
                        name="otp3"
                        onChange={e => {
                          handleChange(e)
                        }}
                        onKeyUp={({ key }) => {
                          if (key === "Backspace" || key === "ArrowLeft") {
                            opt2.current.focus()
                            opt2.current.select()
                          } else {
                            opt4.current.focus()
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.otp3}
                      />
                      <input
                        ref={opt4}
                        type="text"
                        maxlength="1"
                        style={{ width: "40px", fontSize: "24px" }}
                        name="otp4"
                        onChange={e => {
                          handleChange(e)
                        }}
                        onKeyUp={({ key }) => {
                          if (key === "Backspace" || key === "ArrowLeft") {
                            opt3.current.focus()
                            opt3.current.select()
                          } else {
                            opt5.current.focus()
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.otp4}
                      />
                      <input
                        ref={opt5}
                        type="text"
                        maxlength="1"
                        style={{ width: "40px", fontSize: "24px" }}
                        name="otp5"
                        onChange={e => {
                          handleChange(e)
                        }}
                        onKeyUp={({ key }) => {
                          if (key === "Backspace" || key === "ArrowLeft") {
                            opt4.current.focus()
                            opt4.current.select()
                          } else {
                            opt6.current.focus()
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.otp5}
                      />
                      <input
                        ref={opt6}
                        type="text"
                        maxlength="1"
                        style={{ width: "40px", fontSize: "24px" }}
                        name="otp6"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.otp6}
                        onKeyUp={({ key }) => {
                          if (key === "Backspace" || key === "ArrowLeft") {
                            opt5.current.focus()
                            opt5.current.select()
                          }
                        }}
                      />
                    </div>
                    <div
                      className="btn-send-otp"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        let [response, error] = await dispatch(
                          mobileOtp(
                            parseInt(
                              localStorage.getItem("smartway_user_info")
                            ),
                            values.mobile
                          )
                        )
                        if (error) {
                          Swal.fire({
                            text: error,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                          })
                          return
                        }
                        values.otp1 = ""
                        values.otp2 = ""
                        values.otp3 = ""
                        values.otp4 = ""
                        values.otp5 = ""
                        values.otp6 = ""
                        // setOpenModel(false)
                        setInitialValues(values)
                        opt1.current.focus()

                        Swal.fire({
                          title: translate("Resent OTP successfully.","ส่ง OTP สำเร็จ"),
                          icon: "success",
                        })
                      }}
                    >
                      {translate("Resend OTP", "ส่งอีกครั้ง")}
                    </div>
                    <div
                      className="btn-send-otp"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        Swal.showLoading()
                        const otpVerify = await dispatch(submitEditinfo(values))

                        values.otp1 = ""
                        values.otp2 = ""
                        values.otp3 = ""
                        values.otp4 = ""
                        values.otp5 = ""
                        values.otp6 = ""
                        setInitialValues(values)

                        if (otpVerify.data.StatusCode === "0") {
                          dispatch(getProfile())
                          Swal.close()
                          setOtpConfirm(true)
                          setInitialValues(values)
                          getAddress()
                          // setFirstOtpConfirm(true)
                        } else {
                          Swal.fire(otpVerify.data.Message)
                          // getAddress()
                        }
                      }}
                    >
                      {translate("Ok", "ตกลง")}
                    </div>
                  </Modal>
                </form>
              )}
            </Formik>
          </>
        )}

        {address && (
          <>
            <h2>
              {translate("Address", "ที่อยู่ (โปรดระบุข้อมูลเป็นภาษาอังกฤษ)")}
            </h2>
            <p>{translate("(Australian Address)", "(ที่อยู่ออสเตรเลีย)")}</p>
            <Formik
              enableReinitialize={true}
              initialValues={addressInitialValues}
              validate={values => {
                const errors = {}
                if (!values.street_name) {
                  errors.street_name = "Required"
                } else if (!englishCheck.test(values.street_name)) {
                  errors.street_name = "Please fill in English"
                }

                if (!values.state) {
                  errors.state = "Required"
                } else if (!englishCheck.test(values.state)) {
                  errors.state = "Please fill in English"
                }

                if (!values.street_number) {
                  errors.street_number = "Required"
                } else if (!englishCheck.test(values.street_number)) {
                  errors.street_number = "Please fill in English"
                }

                if (!englishCheck.test(values.unit)) {
                  errors.unit = "Please fill in English"
                }

                if (!values.street_type) {
                  errors.street_type = "Required"
                } else if (!englishCheck.test(values.street_type)) {
                  errors.street_type = "Please fill in English"
                }

                if (!values.suburb) {
                  errors.suburb = "Required"
                } else if (!englishCheck.test(values.suburb)) {
                  errors.suburb = "Please fill in English"
                }

                if (!values.postcode) {
                  errors.postcode = "Required"
                } else if (!englishCheck.test(values.postcode)) {
                  errors.postcode = "Please fill in English"
                }
                return errors
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setError("")

                // handleModalNormal()
                // Swal.showLoading()
                const valueresult = await editAddress(values)
                if (valueresult.data.StatusCode === "0") {
                  dispatch(getProfile())
                  Swal.close()
                  getPersonal()
                } else {
                  Swal.fire(valueresult.data.Message)
                }
                setplacesid([])
                setAddressInitialValues(values)
                const uploadInfo = await dispatch(uploadFind)

                if (
                  uploadInfo.data.Object.Documents.length != 0 &&
                  previousUpload == true
                ) {
                  setPreviousUpload(true)
                  setPreviousUploadPoi(true)
                  setPreviousUploadPoa(true)
                } else {
                  setPreviousUpload(false)
                  setPreviousUploadPoi(false)
                  setPreviousUploadPoa(false)
                }

                if (
                  uploadInfo?.data?.Object?.Documents[0]
                    ?.MemberDocumentTypeId === 1
                ) {
                  setPoiFind("Passport")
                  setPoiValue(TypePassport)
                } else if (
                  uploadInfo?.data?.Object?.Documents[0]
                    ?.MemberDocumentTypeId === 4
                ) {
                  setPoiFind("Driver License")
                  setPoiValue(TypeDriverLicense)
                } else if (
                  uploadInfo?.data?.Object?.Documents[0]
                    ?.MemberDocumentTypeId === 6
                ) {
                  setPoiFind("Australia Photo Card")
                  setPoiValue(TypeAustraliaPhotoCard)
                }

                if (
                  uploadInfo?.data?.Object?.Documents[1]
                    ?.MemberDocumentTypeId === 4
                ) {
                  setPoaFind("Driver License")
                  setPoaValue("TypeDriverLicense")
                } else if (
                  uploadInfo?.data?.Object?.Documents[1]
                    ?.MemberDocumentTypeId === 6
                ) {
                  setPoaFind("Australia Photo Card")
                  setPoaValue(TypeAustraliaPhotoCard)
                } else if (
                  uploadInfo?.data?.Object?.Documents[1]
                    ?.MemberDocumentTypeId === 2
                ) {
                  setPoaFind("Utility Bill")
                  setPoaValue(TypeUtilityBill)
                }
                initUploadDocumentFromApi(uploadInfo?.data)

                setUploadImg(
                  uploadInfo?.data?.Object?.Documents[0]?.DocumentHyperlink
                )
                setUploadImg3(
                  uploadInfo?.data?.Object?.Documents[0]?.DocumentBackHyperlink
                )
                setUploadImg1(
                  uploadInfo?.data?.Object?.Documents[1]?.DocumentHyperlink
                )
                setUploadImg2(
                  uploadInfo?.data?.Object?.Documents[1]?.DocumentBackHyperlink
                )

                getIdentification()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm,
                /* and other goodies */
              }) => (
                <>
                  {addressBar && (
                    <div className="currency-group currency-group--transfer currency-group--transfer--full">
                      <div className="currency-input">
                        <div class="registration--input-icon registration--search"></div>

                        <input
                          ref={pacInputRef}
                          placeholder={translate(
                            "Search Address",
                            "ค้นหาที่อยู่"
                          )}
                          type="text"
                          name="address"
                          onBlur={handleBlur}
                          id={"pac-input"}
                        />
                      </div>

                      {placesid.map(data => {
                        return (
                          <div
                            ref={addressoption}
                            className={addressFillClasses}
                          >
                            {" "}
                            <p
                              onClick={async () => {
                                setFieldValue("unit", "")
                                setFieldValue("street_name", "")
                                setFieldValue("street_type", "")
                                setFieldValue("state", "")
                                setFieldValue("postcode", "")
                                setFieldValue("suburb", "")
                                setFieldValue("street_number", "")

                                const finaladdress = await searchaddressplaces(
                                  data.place_id
                                )
                                if (
                                  finaladdress.data.result.address_components[
                                    finaladdress.data.result.address_components.findIndex(
                                      item => item.types[0] == "country"
                                    )
                                  ].long_name == "Australia"
                                ) {
                                  finaladdress.data.result.adr_address
                                    .split("</span>")
                                    .map(data => {
                                      if (data.includes("street-address")) {
                                        setFieldValue(
                                          "street_name",
                                          data.split(">")[1]
                                        )
                                        setFieldValue(
                                          "street_type",
                                          data.split(">")[1].split(" ")[
                                            data.split(">")[1].split(" ")
                                              .length - 1
                                          ]
                                        )
                                      } else if (data.includes("region")) {
                                        setFieldValue(
                                          "state",
                                          data.split(">")[1]
                                        )
                                      } else if (data.includes("postal-code")) {
                                        setFieldValue(
                                          "postcode",
                                          data.split(">")[1]
                                        )
                                      } else if (data.includes("locality")) {
                                        setFieldValue(
                                          "suburb",
                                          data.split(">")[1]
                                        )
                                      }
                                    })
                                  finaladdress.data.result.address_components.map(
                                    data => {
                                      if (
                                        data.types.includes("street_number")
                                      ) {
                                        setFieldValue(
                                          "street_number",
                                          data.short_name
                                        )
                                      }

                                      if (data.types.includes("route")) {
                                        setFieldValue(
                                          "street_name",
                                          data.short_name
                                            ?.split(" ")
                                            .slice(
                                              0,
                                              data.short_name?.split(" ")
                                                ?.length - 1
                                            )
                                            ?.join()
                                        )
                                      }

                                      if (data.types.includes("subpremise")) {
                                        setFieldValue("unit", data.short_name)
                                      }
                                      hideErrorPlace()
                                    }
                                  )
                                } else {
                                  addressoption.current.style.display = "none"
                                  showErrorPlace()
                                }
                                if (addressoption.current) {
                                  addressoption.current.style.display = "none"
                                }
                                // handleAddressBar();
                                setShowPlaceOptions(false)
                              }}
                            >
                              {data.description}
                            </p>
                          </div>
                        )
                      })}
                      <>
                        <p className={errorPlaceClasses}>
                          Please enter an Australian Address
                        </p>
                      </>
                    </div>
                  )}

                  {!addressBar && (
                    <button class="transfer__ghost-button transfer__ghost-button--address">
                      Re-enter Address
                    </button>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Unit Number", "เลขที่")}
                          type="text"
                          name="unit"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.unit}
                        />
                      </div>
                      <div className="error-form">
                        {errors.unit && touched.unit && errors.unit}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Street Number", "หมายเลขถนน")}
                          type="text"
                          name="street_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.street_number}
                        />
                      </div>
                      <div className="error-form">
                        {errors.street_number &&
                          touched.street_number &&
                          errors.street_number}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Street Name", "ชื่อถนน")}
                          type="text"
                          name="street_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.street_name}
                        />
                      </div>
                      <div className="error-form">
                        {errors.street_name &&
                          touched.street_name &&
                          errors.street_name}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Street Type", "ประเภทถนน")}
                          type="text"
                          name="street_type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.street_type}
                        />
                      </div>
                      <div className="error-form">
                        {errors.street_type &&
                          touched.street_type &&
                          errors.street_type}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Suburb", "เมือง")}
                          type="text"
                          name="suburb"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.suburb}
                        />
                      </div>
                      <div className="error-form">
                        {errors.suburb && touched.suburb && errors.suburb}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("State", "จังหวัด")}
                          type="text"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                      </div>
                      <div className="error-form">
                        {errors.state && touched.state && errors.state}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("Postcode", "รหัสไปรษณีย์")}
                          type="text"
                          name="postcode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.postcode}
                        />
                      </div>
                      <div className="error-form">
                        {errors.postcode && touched.postcode && errors.postcode}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder="Australia"
                          disabled
                          type="text"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    {!!error && <div className="error">{error}</div>}
                    <div class="registration-link-holder">
                      <span
                        class="transfer__ghost-button"
                        onClick={() => {
                          setAddressInitialValues(values)
                          setRegNavigation(true)
                          dispatch(getProfile())
                          getAccountDetails()
                        }}
                      >
                        {translate("Back", "กลับ")}
                      </span>
                      <button
                        type="submit"
                        className="btn bold btn--yellow"
                        // onClick={getIdentification}
                      >
                        {translate("Submit and Continue", "ส่งและดำเนินการต่อ")}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </>
        )}

        {identification && (
          <>
            <h2>{translate("Identification", "อัปโหลดเอกสาร")}</h2>
            <p>{translate("Proof of Identity", "เอกสารยืนยันตัวตน")}</p>

            <Formik
              enableReinitialize={true}
              initialValues={uploadInitialValues}
              validate={values => {
                console.log('DEBUG: values',values);
                const errors = {}
                //poi
                if (!values.DocumentNo_poi) {
                  errors.DocumentNo_poi = "Required"
                } else if (!englishCheck.test(values.DocumentNo_poi)) {
                  errors.DocumentNo_poi = "Please fill in English"
                }

                if (
                  !values.DocumentExpireDateISO_poi &&
                  poiValue !== TypeUtilityBill
                ) {
                  errors.DocumentExpireDateISO_poi = "Required"
                }

                let now = new Date()
                let donow = new Date(values.DocumentExpireDateISO_poi)
                if (donow <= now) {
                  errors.DocumentExpireDateISO_poi = "Date is invalid"
                }

                if (
                  poiValue !== TypePassport &&
                  !values.DocumentBackBase64_poi
                ) {
                  errors.DocumentBackBase64_poi = "Required"
                }

                if (!values.DocumentIssue_poi) {
                  errors.DocumentIssue_poi = "Required"
                } else if (!englishCheck.test(values.DocumentIssue_poi)) {
                  errors.DocumentIssue_poi = "Please fill in English"
                }

                if (!values.DocumentBase64_poi) {
                  errors.DocumentBase64_poi = "Required"
                }

                //poa
                console.log("DEBUG: values.DocumentIssuer_poa", poaValue)
                if (poaValue !== "") {
                  if (
                    !values.DocumentIssuer_poa &&
                    poaValue !== TypeUtilityBill
                  ) {
                    errors.DocumentIssuer_poa = "Required"
                  } else if (!englishCheck.test(values.DocumentIssuer_poa)) {
                    errors.DocumentIssuer_poa = "Please fill in English"
                  }
                  if (
                    !values.DocumentExpireDateISO_poa &&
                    poaValue !== TypeUtilityBill
                  ) {
                    errors.DocumentExpireDateISO_poa = "Required"
                  }
                  let donowpoa = new Date(values.DocumentExpireDateISO_poa)
                  if (
                    values.DocumentExpireDateISO_poa &&
                    poaValue !== TypeUtilityBill &&
                    donowpoa <= now
                  ) {
                    errors.DocumentExpireDateISO_poa = "Date is invalid"
                  }

                  if (!values.DocumentNo_poa) {
                    // errors.DocumentNo_poa = "Required"
                  } else if (!englishCheck.test(values.DocumentNo_poa)) {
                    errors.DocumentNo_poa = "Please fill in English"
                  }
                  if (!values.DocumentBase64_poa) {
                    errors.DocumentBase64_poa = "Required"
                  }
                  if (
                    poaValue !== TypeUtilityBill &&
                    !values.DocumentBackBase64_poa
                  ) {
                    errors.DocumentBackBase64_poa = "Required"
                  }

                  if (!values.DocumentState_poi) {
                    errors.DocumentState_poi = "Required"
                  } else if (!englishCheckAZ.test(values.DocumentState_poi)) {
                    errors.DocumentState_poi = "Please fill in English"
                  } else if (
                    values.DocumentState_poi.length < 2 ||
                    values.DocumentState_poi.length > 3
                  ) {
                    errors.DocumentState_poi = "Please fill in 2-3 characters"
                  }
                  //DocumentIssueDateISO_poi
                  if (!values.DocumentIssueDateISO_poi) {
                    errors.DocumentIssueDateISO_poi = "Required"
                  } else {
                    if (
                      moment(values.DocumentIssueDateISO_poi).isAfter(moment())
                    ) {
                      errors.DocumentIssueDateISO_poi = "Date is invalid"
                    }
                  }

                  if (poaValue !== TypeUtilityBill && poaValue !== "") {
                    if (!values.DocumentState_poa) {
                      // errors.DocumentState_poa = "Required"
                    } else if (!englishCheckAZ.test(values.DocumentState_poa)) {
                      errors.DocumentState_poa = "Please fill in English"
                    } else if (
                      values.DocumentState_poa.length < 2 ||
                      values.DocumentState_poa.length > 3
                    ) {
                      errors.DocumentState_poa = "Please fill in 2-3 characters"
                    }
                    //DocumentIssueDateISO_poa
                    if (!values.DocumentIssueDateISO_poa) {
                      errors.DocumentIssueDateISO_poa = "Required"
                    }
                  }
                }
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                console.log("DEBUG: values", values)
                setError("")
                Swal.showLoading()
                values.MemberDocumentTypeId_poi = poiIdChange
                  ? poiId
                  : uploadInitialValues.MemberDocumentTypeId_poi
                  ? uploadInitialValues.MemberDocumentTypeId_poi
                  : poiId
                values.MemberDocumentTypeId_poa = poaIdChange
                  ? poaId
                  : uploadInitialValues.MemberDocumentTypeId_poa
                  ? uploadInitialValues.MemberDocumentTypeId_poa
                  : poaId

                // let hasUpload = false

                const uploadCheckInfo = await dispatch(uploadFind)
                const valueresult = await submitUploadInfo(
                  setUploadDocument(values, uploadCheckInfo)
                )
                if (valueresult.data.StatusCode === "0") {
                  const uploadInfo = await dispatch(uploadFind)
                  if (
                    uploadInfo?.data?.Object?.Documents[0]
                      ?.MemberDocumentTypeId === 1
                  ) {
                    setPoiFind("Passport")
                    setPoiValue(TypePassport)
                  } else if (
                    uploadInfo?.data?.Object?.Documents[0]
                      ?.MemberDocumentTypeId === 4
                  ) {
                    setPoiFind("Driver License")
                    setPoiValue(TypeDriverLicense)
                  } else if (
                    uploadInfo?.data?.Object?.Documents[0]
                      ?.MemberDocumentTypeId === 6
                  ) {
                    setPoiFind("Australia Photo Card")
                    setPoiValue(TypeAustraliaPhotoCard)
                  }

                  if (
                    uploadInfo?.data?.Object?.Documents[1]
                      ?.MemberDocumentTypeId === 4
                  ) {
                    setPoaFind("Driver License")
                    setPoaValue(TypeDriverLicense)
                  } else if (
                    uploadInfo?.data?.Object?.Documents[1]
                      ?.MemberDocumentTypeId === 6
                  ) {
                    setPoaFind("Australia Photo Card")
                    setPoaValue(TypeAustraliaPhotoCard)
                  } else if (
                    uploadInfo?.data?.Object?.Documents[1]
                      ?.MemberDocumentTypeId === 2
                  ) {
                    setPoaFind("Utility Bill")
                    setPoaValue(TypeUtilityBill)
                  }
                  initUploadDocumentFromApi(uploadInfo?.data)
                  setUploadImg(
                    uploadInfo?.data?.Object?.Documents[0]?.DocumentHyperlink
                  )
                  setUploadImg3(
                    uploadInfo?.data?.Object?.Documents[0]
                      ?.DocumentBackHyperlink
                  )
                  setUploadImg1(
                    uploadInfo?.data?.Object?.Documents[1]?.DocumentHyperlink
                  )
                  setUploadImg2(
                    uploadInfo?.data?.Object?.Documents[1]
                      ?.DocumentBackHyperlink
                  )

                  setPreviousUpload(true)
                  setPreviousUploadPoi(true)
                  setPreviousUploadPoa(true)
                  setPoiIdChange(false)
                  setPoaIdChange(false)

                  Swal.close()
                  getPersonal()
                } else if (valueresult.data.StatusCode === "01") {
                  if (localStorage.getItem("smartway_auth")) {
                    window.location.href = "/login?session=expired"
                    // console.log('DEBUG: "/login?session=expired"');
                  }
                } else {
                  Swal.fire(valueresult.data.Message)
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
                /* and other goodies */
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          disabled={!getProfileStatus?.profile?.RegisterStep}
                          id="poidocumenttype"
                          name="poidocumenttype"
                          onChange={e => {
                            setPoiValue(e.target.value)
                            setPoiId(
                              Number(
                                e.target.options[e.target.selectedIndex].dataset
                                  .documentid
                              )
                            )
                            setPoiIdChange(true)
                            setUploadImg("")
                            setUploadImg3("")
                            values.DocumentNo_poi = ""
                            values.DocumentExpireDateISO_poi = ""
                            values.DocumentIssue_poi = ""
                            values.DocumentBase64_poi = ""
                            values.DocumentName_poi = ""
                            values.DocumentBase64_poi = ""
                            setPreviousUploadPoi(false)
                            clearUploadPoi(values)
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          <option
                            selected={poiFind === "Type of Document"}
                            value=""
                          >
                            {translate("Type of Document", "ประเภทเอกสาร")}
                          </option>
                          <option
                            value={TypePassport}
                            selected={poiFind === "Passport"}
                            data-documentid="1"
                          >
                            Passport
                          </option>
                          {poaValue !== TypeDriverLicense && (
                            <option
                              value={TypeDriverLicense}
                              selected={poiFind === "Driver License"}
                              data-documentid="4"
                            >
                              Driver License
                            </option>
                          )}
                          {poaValue !== TypeAustraliaPhotoCard && (
                            <option
                              value={TypeAustraliaPhotoCard}
                              selected={poiFind === "Australia Photo Card"}
                              data-documentid="6"
                            >
                              Australia Photo Card
                            </option>
                          )}
                        </select>
                      </div>
                    </div>

                    {poiValue === TypePassport ? (
                      <div className="currency-group currency-group--transfer">
                        <div className="currency-input">
                          <input
                            placeholder={translate(
                              "Passport Number",
                              "เลขที่พาสปอร์ต"
                            )}
                            type="text"
                            name="DocumentNo_poi"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DocumentNo_poi}
                          />
                        </div>
                        <div className="error-form">
                          {errors.DocumentNo_poi &&
                            touched.DocumentNo_poi &&
                            errors.DocumentNo_poi}
                        </div>
                      </div>
                    ) : (
                      <div className="currency-group currency-group--transfer">
                        <div className="currency-input">
                          <input
                            placeholder={translate(
                              "Document Number",
                              "เลขที่เอกสาร"
                            )}
                            type="text"
                            name="DocumentNo_poi"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.DocumentNo_poi}
                          />
                        </div>
                        <div className="error-form">
                          {errors.DocumentNo_poi &&
                            touched.DocumentNo_poi &&
                            errors.DocumentNo_poi}
                        </div>
                      </div>
                    )}

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate(
                            "Issue Authority",
                            "ผู้มีอำนาจออก"
                          )}
                          type="text"
                          name="DocumentIssue_poi"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DocumentIssue_poi}
                        />
                      </div>
                      <div className="error-form">
                        {errors.DocumentIssue_poi &&
                          touched.DocumentIssue_poi &&
                          errors.DocumentIssue_poi}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("State/Country", "รัฐ/ประเทศ")}
                          type="text"
                          name="DocumentState_poi"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DocumentState_poi}
                        />
                      </div>
                      <div className="error-form">
                        {errors.DocumentState_poi &&
                          touched.DocumentState_poi &&
                          errors.DocumentState_poi}
                      </div>
                    </div>
                    <DateComponent
                      placeholder={translate("Expire Date", "วันหมดอายุ")}
                      value={values.DocumentExpireDateISO_poi}
                      handleChange={val => {
                        handleChange(val)
                      }}
                      name="DocumentExpireDateISO_poi"
                      id="DocumentExpireDateISO_poi"
                      error={
                        poiValue !== TypeUtilityBill &&
                        errors.DocumentExpireDateISO_poi &&
                        touched.DocumentExpireDateISO_poi &&
                        errors.DocumentExpireDateISO_poi
                      }
                    />
                    <DateComponent
                      placeholder={translate("Issue Date", "วันที่ออกเอกสาร")}
                      value={values.DocumentIssueDateISO_poi}
                      handleChange={val => {
                        handleChange(val)
                      }}
                      name="DocumentIssueDateISO_poi"
                      id="DocumentIssueDateISO_poi"
                      error={
                        errors.DocumentIssueDateISO_poi &&
                        touched.DocumentIssueDateISO_poi &&
                        errors.DocumentIssueDateISO_poi
                      }
                    />
                    <div className={uploadWrapperClasses}>
                      <div className={uploadClasses}>
                        {poiValue === TypeDriverLicense && (
                          <p className="upload-label">Driver License (Front)</p>
                        )}
                        {poiValue === TypePassport && (
                          <p className="upload-label">Passport</p>
                        )}
                        {poiValue === TypeAustraliaPhotoCard && (
                          <p className="upload-label">
                            Australia Photo Card (Front)
                          </p>
                        )}
                        <div
                          name=""
                          onClick={() => {
                            uploadImges.current.click()

                            //}
                          }}
                        >
                          {uploadImg ? (
                            <img
                              style={{ width: "100%" }}
                              className="uploadimg"
                              src={uploadImg}
                            />
                          ) : (
                            <div className="uploadButton">Upload Photo</div>
                          )}
                        </div>

                        <input
                          style={{ display: "none" }}
                          ref={uploadImges}
                          type="file"
                          onChange={async info => {
                            if (
                              info.target.files[0].type.includes("/jpeg") ||
                              info.target.files[0].type.includes("/jpg") ||
                              info.target.files[0].type.includes("/png")
                            ) {
                              if (info.target.files[0].size >= 3000000) {
                                Swal.fire("File size should be less then 3 MB.")
                                return
                              }
                              //  if (info.file.status === "uploading") {
                              //   Swal.showLoading()
                              //   return;
                              // }
                              setFieldValue(
                                "DocumentName_poi",
                                info.target.files[0].name
                              )
                              Swal.close()

                              Swal.fire("Compressing image...")
                              const imageUrl = await fileChangedHandler(
                                info.target.files[0]
                              )
                              setUploadImg(imageUrl)
                              setFieldValue("DocumentBase64_poi", imageUrl)

                              setPreviousUploadPoi(false)
                              Swal.close()
                            } else {
                              Swal.fire("Invalid file")
                              return
                            }
                          }}
                        />
                      </div>
                      <div className="error-form">
                        {errors.DocumentBase64_poi &&
                          touched.DocumentBase64_poi &&
                          errors.DocumentBase64_poi}
                      </div>
                    </div>

                    {poiValue !== TypePassport && (
                      <div className={uploadWrapperClasses3}>
                        <div className={uploadClasses}>
                          {poiValue === TypeDriverLicense && (
                            <p className="upload-label">
                              Driver License (Back)
                            </p>
                          )}
                          {poiValue === TypeAustraliaPhotoCard && (
                            <p className="upload-label">
                              Australia Photo Card (Back)
                            </p>
                          )}
                          <div
                            name=""
                            onClick={() => {
                              uploadImges3.current.click()

                              //}
                            }}
                          >
                            {uploadImg3 ? (
                              <img
                                style={{ width: "100%" }}
                                className="uploadimg"
                                src={uploadImg3}
                              />
                            ) : (
                              <div className="uploadButton">Upload Photo</div>
                            )}
                          </div>

                          <input
                            style={{ display: "none" }}
                            ref={uploadImges3}
                            type="file"
                            onChange={async info => {
                              if (
                                info.target.files[0].type.includes("/jpeg") ||
                                info.target.files[0].type.includes("/jpg") ||
                                info.target.files[0].type.includes("/png")
                              ) {
                                if (info.target.files[0].size >= 3000000) {
                                  Swal.fire(
                                    "File size should be less then 3 MB."
                                  )
                                  return
                                }
                                //  if (info.file.status === "uploading") {
                                //   Swal.showLoading()
                                //   return;
                                // }
                                setFieldValue(
                                  "DocumentBackName_poi",
                                  info.target.files[0].name
                                )
                                Swal.close()

                                Swal.fire("Compressing image...")
                                const imageUrl = await fileChangedHandler(
                                  info.target.files[0]
                                )
                                setUploadImg3(imageUrl)
                                setFieldValue(
                                  "DocumentBackBase64_poi",
                                  imageUrl
                                )

                                setPreviousUploadPoi(false)
                                Swal.close()
                              } else {
                                Swal.fire("Invalid file")
                                return
                              }
                            }}
                          />
                        </div>
                        <div className="error-form">
                          {errors.DocumentBase64_poi &&
                            touched.DocumentBase64_poi &&
                            errors.DocumentBase64_poi}
                        </div>
                      </div>
                    )}

                    <p class="paragraph--margintop">
                      {translate(
                        "Proof of Australian Address",
                        "เอกสารยืนยันที่อยู่ออสเตรเลีย"
                      )}
                    </p>
                    <p></p>

                    {poiValue === TypePassport && (
                      <div
                        className="currency-group"
                        style={{ display: "none" }}
                      ></div>
                    )}

                    <div className="currency-group currency-group--transfer currency-group--transfer--nomargin">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          disabled={!getProfileStatus?.profile?.RegisterStep}
                          id="poadocumenttype"
                          name="poadocumenttype"
                          onChange={e => {
                            setPoaValue(e.target.value)
                            setPoaId(
                              Number(
                                e.target.options[e.target.selectedIndex].dataset
                                  .documentid
                              )
                            )
                            setPoaIdChange(true)

                            setUploadImg1("")
                            setUploadImg2("")
                            values.DocumentNo_poa = ""
                            values.DocumentExpireDateISO_poa = ""
                            values.DocumentIssue_poa = ""
                            values.DocumentBase64_poa = ""
                            values.DocumentName_poa = ""
                            values.DocumentIssuer_poa = ""
                            values.DocumentBase64_poa = ""
                            setPreviousUploadPoa(false)
                            clearUploadPoa(values)
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                        >
                          <option
                            selected={poaFind === "Type of Document"}
                            value=""
                          >
                            {translate("Type of Document", "ประเภทเอกสาร")}
                          </option>
                          {poiValue !== TypeDriverLicense && (
                            <option
                              value={TypeDriverLicense}
                              selected={poaFind === "Driver License"}
                              data-documentid="4"
                            >
                              Driver License
                            </option>
                          )}
                          {poiValue !== TypeAustraliaPhotoCard && (
                            <option
                              value={TypeAustraliaPhotoCard}
                              selected={poaFind === "Australia Photo Card"}
                              data-documentid="6"
                            >
                              Australia Photo Card
                            </option>
                          )}
                          <option
                            value={TypeUtilityBill}
                            selected={poaFind === "Utility Bill"}
                            data-documentid="2"
                          >
                            Utility Bill
                          </option>
                        </select>
                        {/* <input
                          placeholder="Driver's License (Front and Back)"
                          type="text"
                          name="driverlicense"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        /> */}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer currency-group--transfer--nomargin">
                      <div className="currency-input">
                        <input
                          placeholder={translate(
                            "License Number",
                            "เลขที่เอกสาร"
                          )}
                          type="text"
                          name="DocumentNo_poa"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DocumentNo_poa}
                        />
                      </div>
                      <div className="error-form">
                        {errors.DocumentNo_poa &&
                          touched.DocumentNo_poa &&
                          errors.DocumentNo_poa}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate(
                            "Issue Authority",
                            "ผู้มีอำนาจออก"
                          )}
                          type="text"
                          name="DocumentIssuer_poa"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DocumentIssuer_poa}
                        />
                      </div>
                      <div className="error-form"></div>
                      <div className="error-form">
                        {errors.DocumentIssuer_poa &&
                          touched.DocumentIssuer_poa &&
                          errors.DocumentIssuer_poa}
                      </div>
                    </div>
                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input">
                        <input
                          placeholder={translate("State/Country", "รัฐ/ประเทศ")}
                          type="text"
                          name="DocumentState_poa"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DocumentState_poa}
                        />
                      </div>
                      <div className="error-form">
                        {errors.DocumentState_poa &&
                          touched.DocumentState_poa &&
                          errors.DocumentState_poa}
                      </div>
                    </div>
                    <DateComponent
                      placeholder={translate("Expire Date", "วันหมดอายุ")}
                      value={values.DocumentExpireDateISO_poa}
                      handleChange={val => {
                        handleChange(val)
                      }}
                      name="DocumentExpireDateISO_poa"
                      id="DocumentExpireDateISO_poa"
                      error={
                        poaValue !== TypeUtilityBill &&
                        errors.DocumentExpireDateISO_poa &&
                        touched.DocumentExpireDateISO_poa &&
                        errors.DocumentExpireDateISO_poa
                      }
                    />
                    <DateComponent
                      placeholder={translate("Issue Date", "วันที่ออกเอกสาร")}
                      value={values.DocumentIssueDateISO_poa}
                      handleChange={val => {
                        handleChange(val)
                      }}
                      name="DocumentIssueDateISO_poa"
                      id="DocumentIssueDateISO_poa"
                      error={
                        errors.DocumentIssueDateISO_poa &&
                        touched.DocumentIssueDateISO_poa &&
                        errors.DocumentIssueDateISO_poa
                      }
                    />

                    <div className={uploadWrapperClasses1}>
                      <div className={uploadClasses1}>
                        {poaValue === TypeDriverLicense && (
                          <p className="upload-label">Driver License (Front)</p>
                        )}
                        {poaValue === TypeUtilityBill && (
                          <p className="upload-label">
                            Utility Bill (Including Bank Statement)
                          </p>
                        )}
                        {poaValue === TypeAustraliaPhotoCard && (
                          <p className="upload-label">
                            Australia Photo Card (Front)
                          </p>
                        )}

                        <div
                          name=""
                          onClick={() => uploadImges1.current.click()}
                        >
                          {uploadImg1 ? (
                            <img
                              style={{ width: "100%" }}
                              className="uploadimg"
                              src={uploadImg1}
                            />
                          ) : (
                            <div className="uploadButton">Upload Photo</div>
                          )}
                        </div>
                        <input
                          style={{ display: "none" }}
                          ref={uploadImges1}
                          type="file"
                          onChange={async info => {
                            if (
                              info.target.files[0].type.includes("/jpeg") ||
                              info.target.files[0].type.includes("/jpg") ||
                              info.target.files[0].type.includes("/png")
                            ) {
                              if (info.target.files[0].size >= 3000000) {
                                Swal.fire("File size should be less then 3 MB.")
                                return
                              }
                              //  if (info.file.status === "uploading") {
                              //   Swal.showLoading()
                              //   return;
                              // }
                              setFieldValue(
                                "DocumentName_poa",
                                info.target.files[0].name
                              )
                              Swal.close()

                              Swal.fire("Compressing image...")
                              const imageUrl = await fileChangedHandler(
                                info.target.files[0]
                              )
                              setUploadImg1(imageUrl)
                              setFieldValue("DocumentBase64_poa", imageUrl)

                              setPreviousUploadPoa(false)
                              Swal.close()
                            } else {
                              Swal.fire("Invalid file")
                              return
                            }
                          }}
                        />
                      </div>
                      <div className="error-form">
                        {errors.DocumentBase64_poa &&
                          touched.DocumentBase64_poa &&
                          errors.DocumentBase64_poa}
                      </div>
                    </div>
                    {poaValue !== TypeUtilityBill && (
                      <div className={uploadWrapperClasses2}>
                        <div className={uploadClasses2}>
                          {poaValue === TypeDriverLicense && (
                            <p className="upload-label">
                              Driver License (Back)
                            </p>
                          )}
                          {poaValue === TypeAustraliaPhotoCard && (
                            <p className="upload-label">
                              Australia Photo Card (Back)
                            </p>
                          )}

                          <div
                            name=""
                            onClick={() => uploadImges2.current.click()}
                          >
                            {uploadImg2 ? (
                              <img
                                style={{ width: "100%" }}
                                className="uploadimg"
                                src={uploadImg2}
                              />
                            ) : (
                              <div className="uploadButton">Upload Photo</div>
                            )}
                          </div>
                          <input
                            style={{ display: "none" }}
                            ref={uploadImges2}
                            type="file"
                            onChange={async info => {
                              if (
                                info.target.files[0].type.includes("/jpeg") ||
                                info.target.files[0].type.includes("/jpg") ||
                                info.target.files[0].type.includes("/png")
                              ) {
                                if (info.target.files[0].size >= 3000000) {
                                  Swal.fire(
                                    "File size should be less then 3 MB."
                                  )
                                  return
                                }
                                //  if (info.file.status === "uploading") {
                                //   Swal.showLoading()
                                //   return;
                                // }
                                setFieldValue(
                                  "DocumentBackName_poa",
                                  info.target.files[0].name
                                )
                                Swal.close()

                                Swal.fire("Compressing image...")
                                const imageUrl = await fileChangedHandler(
                                  info.target.files[0]
                                )
                                setUploadImg2(imageUrl)
                                setFieldValue(
                                  "DocumentBackBase64_poa",
                                  imageUrl
                                )

                                setPreviousUploadPoa(false)
                                Swal.close()
                              } else {
                                Swal.fire("Invalid file")
                                return
                              }
                            }}
                          />
                        </div>
                        <div className="error-form">
                          {errors.DocumentBackBase64_poa &&
                            touched.DocumentBackBase64_poa &&
                            errors.DocumentBackBase64_poa}
                        </div>
                      </div>
                    )}

                    {!!error && <div className="error">{error}</div>}
                    <div class="registration-link-holder">
                      <span
                        class="transfer__ghost-button"
                        onClick={() => {
                          getAddress()
                        }}
                      >
                        {translate("Back", "กลับ")}
                      </span>
                      <button type="submit" className="btn bold btn--yellow">
                        {translate("Submit and Continue", "ส่งและดำเนินการต่อ")}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </>
        )}

        {personalDetails && (
          <>
            <h2>{translate("Other Details", "ข้อมูลส่วนตัว")}</h2>

            <Formik
              enableReinitialize={true}
              initialValues={{
                GenderId: getProfileStatus?.profile?.GenderId,
                OccupationId: getProfileStatus?.profile?.OccupationId,
                //"OccupationOtherText":"",
                SalaryId: getProfileStatus?.profile?.SalaryId,
                FrequencyOfTransferId:
                  getProfileStatus?.profile?.FrequencyOfTransferId,
                ConvenientTimeToContactId:
                  getProfileStatus?.profile?.ConvenientTimeToContactId,
              }}
              validate={values => {
                const errors = {}

                if (!values.GenderId) {
                  errors.GenderId = "Required"
                }
                if (!values.OccupationId) {
                  errors.OccupationId = "Required"
                }
                // if (!values.OccupationOtherText) {
                //   errors.OccupationOtherText = "Required";
                // }
                if (!values.SalaryId) {
                  errors.SalaryId = "Required"
                }
                if (!values.FrequencyOfTransferId) {
                  errors.FrequencyOfTransferId = "Required"
                }
                if (!values.ConvenientTimeToContactId) {
                  errors.ConvenientTimeToContactId = "Required"
                }
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setError("")
                handleModalNormal()
                // Swal.showLoading()
                const resultvalues = await editPersonal(values)
                if (resultvalues.data.StatusCode === "0") {
                  Swal.close()
                  showTransferModalNormal(true)
                } else {
                  Swal.fire(resultvalues.data.Message)
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          name="GenderId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.GenderId || ""}
                        >
                          <option selected value="">
                            {translate("Gender", "เพศ")}
                          </option>

                          {allProfiles.gender &&
                            allProfiles.gender.map(gen => {
                              return (
                                <option value={gen.GenderId}>
                                  {translate(
                                    gen.GenderName_en_US,
                                    gen.GenderName_th_TH
                                  )}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.GenderId && touched.GenderId && errors.GenderId}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          name="OccupationId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.OccupationId}
                        >
                          <option selected value="">
                            {translate("Occupation", "อาชีพ")}
                          </option>
                          {allProfiles.occupation &&
                            allProfiles.occupation.map(occ => {
                              return (
                                <option value={occ.OccupationId}>
                                  {translate(
                                    occ.OccupationName_en_US,
                                    occ.OccupationName_th_TH
                                  )}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.OccupationId &&
                          touched.OccupationId &&
                          errors.OccupationId}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          name="SalaryId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.SalaryId}
                        >
                          <option selected value="">
                            {translate("Salary", "เงินเดือน")}
                          </option>
                          {allProfiles.salary &&
                            allProfiles.salary.map(sal => {
                              return (
                                <option value={sal.SalaryId}>
                                  {translate(
                                    sal.SalaryName_en_US,
                                    sal.SalaryName_th_TH
                                  )}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.SalaryId && touched.SalaryId && errors.SalaryId}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FrequencyOfTransferId}
                          name="FrequencyOfTransferId"
                        >
                          <option selected value="">
                            {translate(
                              "Frequency of Transfer",
                              "ความถี่ในการโอน"
                            )}
                          </option>
                          {allProfiles.frequencyTransfer &&
                            allProfiles.frequencyTransfer.map(freq => {
                              return (
                                <option value={freq.FrequencyOfTransferId}>
                                  {translate(
                                    freq.FrequencyOfTransferName_en_US,
                                    freq.FrequencyOfTransferName_th_TH
                                  )}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.FrequencyOfTransferId &&
                          touched.FrequencyOfTransferId &&
                          errors.FrequencyOfTransferId}
                      </div>
                    </div>

                    <div className="currency-group currency-group--transfer">
                      <div className="currency-input currency-input--selectwrapper">
                        <select
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.ConvenientTimeToContactId}
                          name="ConvenientTimeToContactId"
                        >
                          <option selected value="">
                            {translate(
                              "Convenient Time for Contact",
                              "เวลาที่สะดวกในการติดต่อ"
                            )}
                          </option>
                          {allProfiles.timeConnect &&
                            allProfiles.timeConnect.map(timec => {
                              return (
                                <option value={timec.ConvenientTimeToContactId}>
                                  {translate(
                                    timec.ConvenientTimeToContactName_en_US,
                                    timec.ConvenientTimeToContactName_th_TH
                                  )}
                                </option>
                              )
                            })}
                        </select>
                      </div>
                      <div className="error-form">
                        {errors.ConvenientTimeToContactId &&
                          touched.ConvenientTimeToContactId &&
                          errors.ConvenientTimeToContactId}
                      </div>
                    </div>

                    {!!error && <div className="error">{error}</div>}
                    <div class="registration-link-holder">
                      <span
                        class="transfer__ghost-button"
                        onClick={async () => {
                          const uploadInfo = await dispatch(uploadFind)

                          if (
                            uploadInfo.data.Object.Documents.length != 0 &&
                            previousUpload == true
                          ) {
                            setPreviousUpload(true)
                            setPreviousUploadPoi(true)
                            setPreviousUploadPoa(true)
                          } else {
                            setPreviousUpload(false)
                            setPreviousUploadPoi(false)
                            setPreviousUploadPoa(false)
                          }

                          if (
                            uploadInfo?.data?.Object?.Documents[0]
                              ?.MemberDocumentTypeId == 1
                          ) {
                            setPoiFind("Passport")
                            setPoiValue(TypePassport)
                          } else if (
                            uploadInfo?.data?.Object?.Documents[0]
                              ?.MemberDocumentTypeId === 4
                          ) {
                            setPoiFind("Driver License")
                            setPoiValue(TypeDriverLicense)
                          } else if (
                            uploadInfo?.data?.Object?.Documents[0]
                              ?.MemberDocumentTypeId === 6
                          ) {
                            setPoiFind("Australia Photo Card")
                            setPoiValue(TypeAustraliaPhotoCard)
                          }

                          if (
                            uploadInfo?.data?.Object?.Documents[1]
                              ?.MemberDocumentTypeId === 4
                          ) {
                            setPoaFind("Driver License")
                            setPoaValue(TypeDriverLicense)
                          } else if (
                            uploadInfo?.data?.Object?.Documents[1]
                              ?.MemberDocumentTypeId === 6
                          ) {
                            setPoaFind("Australia Photo Card")
                            setPoaValue(TypeAustraliaPhotoCard)
                          } else if (
                            uploadInfo?.data?.Object?.Documents[1]
                              ?.MemberDocumentTypeId === 2
                          ) {
                            setPoaFind("Utility Bill")
                            setPoaValue(TypeUtilityBill)
                          }
                          initUploadDocumentFromApi(uploadInfo?.data)
                          setUploadImg(
                            uploadInfo?.data?.Object?.Documents[0]
                              ?.DocumentHyperlink
                          )
                          setUploadImg3(
                            uploadInfo?.data?.Object?.Documents[0]
                              ?.DocumentBackHyperlink
                          )
                          setUploadImg1(
                            uploadInfo?.data?.Object?.Documents[1]
                              ?.DocumentHyperlink
                          )
                          setUploadImg2(
                            uploadInfo?.data?.Object?.Documents[1]
                              ?.DocumentBackHyperlink
                          )

                          getIdentification()
                        }}
                      >
                        {translate("Back", "กลับ")}
                      </span>
                      <button
                        type="submit"
                        className="btn bold btn--yellow"
                        // onClick={getComplete}
                      >
                        {translate("Complete", "ส่งและดำเนินการต่อ")}
                      </button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </>
        )}
      </section>

      {transferModalNormal && (
        <div className="transfer__modal">
          <div className={modalClasses}>
            <div className="transfer__modal--cross" onClick={handleModalNormal}>
              {/* <img src={crossIcon} className="transfer__modal--cross-img" /> */}
            </div>
            <h2>
              {translate("Registration Successful", "การลงทะเบียนสำเร็จ")}
            </h2>
            <p>
              {translate(
                "Thank you for registering with Smartway System.",
                "ขอขอบคุณที่ลงทะเบียนกับระบบ Smartway"
              )}
            </p>

            <button
              type="submit"
              className="btn bold btn--yellow"
              onClick={e => {
                e.preventDefault()
                handleModalNormal()
                navigate(
                  typeof window !== "undefined" &&
                    window.location.href.includes("/th")
                    ? "/th/"
                    : "/"
                )
              }}
            >
              {translate("Homepage", "หน้าแรก")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default RegistrationTwo
