import { Link } from "gatsby"
import React from "react"

const PromotionCode = ({ data }) => {
  return (
    <div className={"text-center bold"}>
      <div>
        <div>Promotion Code</div>
        <button className={"btn btn-outline-info small"} disabled={true}>
          {data}
        </button>
      </div>
      <div className={"mt-4"}>
        {/*<div>Use Code</div>*/}
        {typeof window !== "undefined" &&
        window.location.href.includes("/th") ? (
          <Link
            className={"btn btn-primary"}
            to={"/th/money_transfer?&promoCode=" + data}
          >
            Use Code
          </Link>
        ) : (
          <Link
            className={"btn btn-primary"}
            to={"/en/money_transfer?&promoCode=" + data}
          >
            Use Code
          </Link>
        )}
      </div>
    </div>
  )
}
export default PromotionCode
