import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import { navigate } from "gatsby"
import Swal from "sweetalert2"
import translate from "src/helpers/language"
import {
  checkOverLimit,
  checkPromoCode,
  deleteAccount,
  getAllBank,
  getCity,
  getCurrentExchangeRate,
  getCurrentExchangeRate2,
  getExchangeRate,
  getPaymentMethod,
  getPostel,
  getStates,
  termAndCondition,
  transferMoneyCheck,
} from "src/state/action/currency"

import {
  getProfile,
  getReason,
  moneyTransferEditProfile,
  moneyTransferGetProfile,
} from "src/state/action/profile"

import { LinkHandler } from "src/sws-ui"
import classNames from "classnames"
import payidLogo from "src/images/icons/payid-logo.png"
import promoInvalidImg from "src/images/icons/promo_invalid.png"
import promoValidImg from "src/images/icons/promo_valid.png"
import deleteImg from "src/images/icons/delete.png"
import crossIcon from "src/images/icons/cross.png"
import radioSelected from "src/images/icons/radio_selected.png"
import radioUnselected from "src/images/icons/radio_unselected.png"
import TermAndCondition from "./component/TermAndCondition"
import SlipPreviewComponent from "./component/SlipPreview"
import { CurrencyDetails } from "../../state/reducer/currencyReducer"

let currencyState = CurrencyDetails
const englishCheck = /^[A-Za-z0-9@%-+,><|'"!#$\-*/\()_.-~ ]*$/
const englishCheckNotNumber = /^[A-Z@~`!@#$%^ &*()_=+\\\\';:\"\\/?>.<,-]*$/i
const englishCheckNotSpecial = /^[A-Za-z0-9 ]*$/
const CurrencyInput = ({ label, currency, value, placeholder }) => {
  return (
    <div className="currency-group">
      <label htmlFor="">{label}</label>
      <div className="currency-input">
        <input placeholder={placeholder} value={value} />
        <span>{currency}</span>
      </div>
    </div>
  )
}

const ExistingAccount = ({
  accountNo,
  accountNm,
  bank,
  existingAccountCheck,
  onClick,
  id,
  key,
  clearAccDet,
  showSelectAccount,
}) => {
  const dispatch = useDispatch()
  const existAccountClasses = classNames("existing__account", {
    "existing__account-active": existingAccountCheck == accountNo,
  })

  return (
    <div key={key} className={existAccountClasses} onClick={onClick}>
      <img src={radioUnselected} className="inactive_existing" />
      <img src={radioSelected} className="active_existing" />
      <div>
        <p>{translate("Account Number", "หมายเลขบัญชี")}</p>
        <p>{accountNo}</p>
      </div>
      <div>
        <p>{translate("Account Name", "ชื่อบัญชี")}</p>
        <p>{accountNm}</p>
      </div>
      <div>
        <p>{translate("Bank", "ธนาคาร")}</p>
        <p>{bank}</p>
      </div>
      <div
        style={{ cursor: "pointer", margin: "0" }}
        onClick={() => {
          // Swal.showLoading()
          // confirm delete
          Swal.fire({
            // title: translate("Are you sure?", "คุณแน่ใจหรือไม่?"),
            title: translate(
              "You want to delete this account?",
              "คุณต้องการลบบัญชีนี้หรือไม่?"
            ),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: translate("Yes, delete it!", "ลบบัญชี"),
          }).then(result => {
            if (result.isConfirmed) {
              dispatch(
                deleteAccount({
                  MemberAccountIds: [id],
                })
              )
              Swal.fire({
                // translate("Deleted!", "ลบบัญชี"),
                title: translate(
                  "Deleted Successfully !",
                  "ลบบัญชีเสร็จสมบูรณ์"
                ),
                icon: "success",
              }).then(() => {
                dispatch(getProfile())
                clearAccDet()
                // showSelectAccount()
              })
            }
          })
        }}
      >
        <img style={{ margin: "0", marginRight: "5px" }} src={deleteImg}></img>
        <span style={{ marginTop: "2px" }}>
          {translate("Delete account", "ลบบัญชี")}
        </span>
      </div>
    </div>
  )
}


const MoneyTransfer = () => {
  //dispatcher
  const dispatch = useDispatch()
  const allState = useSelector(state => state)
  const [rater, setRater] = useState("")
  const { profile } = allState
  currencyState = allState.currency
  const [promoValue, setPromoValue] = useState("")
  const [promoValid, setPromoValid] = useState("")

  const [transferModal, showTransferModal] = useState(false)
  const [selectAccount, showSelectAccount] = useState(true)
  const [newAccount, showNewAccount] = useState(false)
  const [existingAccount, showExistingAccount] = useState(false)
  const [accountName, setAccountName] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [memAccId, setMemAccId] = useState("")
  const [activeExistingAccount, setActiveAccount] = useState("")
  const [transferAmount, setTransferAmount] = useState("0")
  const [receiveAmount, setReceiveAmount] = useState(0.0)
  const [saveCreateAccount, setSaveCreateAccount] = useState(false)
  const [accountBank, setAccountBank] = useState("")
  const [alertAmount, setAlertAmount] = useState(false)
  const [showReasons, setShowReasons] = useState(false)
  const [reasonBox, showReasonBox] = useState(false)
  const [loginBox, showLoginBox] = useState(false)
  const [poliForm, setPoliForm] = useState(false)
  const [resonValue, setResonValue] = useState("")
  const [poliLinker, setPoliLink] = useState("")
  const [promoCodeReal, setPromoCodeReal] = useState("")
  const [currentReason, changeCurrentReason] = useState("")
  const [changeTransferAmount, setChangeTransferAmount] = useState("1,000.00-")
  const [reasonBoxValue, setReasonBoxValue] = useState("")
  const [reasonError, setReasonError] = useState(false)
  const [promoStatus, setPromoStatus] = useState(false)
  const [popTermAndCondition, setPopTermAndCondition] = useState(false)
  const [methodFromPromoCode, setMethodFromPromoCode] = useState([])
  const [transferBtnStatus, setTransferBtnStatus] = useState(true)
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(1)
  const [termAndConditionObject, setTermAndConditionObject] = useState({
    IsAcceptCurrentVersion: false,
    TermAndConditionId: 0,
    TermTitle: "Smartway System Pty Ltd - Terms and Conditions ",
    TermBody: "<p>Smartway System Pty Ltd - Terms and Conditions</p>",
  })
  // const [slipPreview, setSlipPreview] = useState({
  //   NavigateURL: null,
  //   SlipPreviewUrl:
  //     "http://122.155.197.101:8080/DevWebApi.Winplus/Slip/testSlip?PaymentMethodId=2&TransferMoneyId=57015",
  //   Token: null,
  //   RefNo: "9893689096",
  //   PayID: "payments@smartwaysystem.com.au",
  //   TransferMoneyId: 57015,
  // })
  const [slipPreview, setSlipPreview] = useState({
    "NavigateURL": null,
    "SlipPreviewUrl": "https://apinewdesign.smartwaysystem.com.au/Slip/testSlip?PaymentMethodId=3\u0026TransferMoneyId=57247",
    "Token": null,
    "RefNo": "55670517000005",
    "PayID": null,
    "BSB": "802-985",
    "AccountNo": "918045366",
    "TransferMoneyId": 57247
  })
  const [popSlipReview, setPopSlipReview] = useState(false)

  const [transferValues, setTransferValues] = useState({
    send: transferAmount,
    accountNumbers: accountNumber,
    accountNames: accountName,
    promo: "",
    login:
      typeof window !== "undefined" && localStorage.getItem("smartway_auth"),
  })

  useEffect(() => {
    const currentUrl = window.location.href
    const urlParams = new URLSearchParams(currentUrl)
    const promoCode = urlParams.get("promoCode")
    if (promoCode) {
      setTransferValues({
        send: transferAmount,
        accountNumbers: accountNumber,
        accountNames: accountName,
        promo: promoCode,
        login:
          typeof window !== "undefined" &&
          localStorage.getItem("smartway_auth"),
      })
    }
    return () => {}
  }, [])

  //curentRate
  useEffect(() => {
    setRater(allState.currency?.exchangeRateCurrent.RateText)
    setReceiveAmount(
      parseFloat(transferAmount.toString().replace(",", "")) *
        allState.currency?.exchangeRateCurrent.Rate
    )
    if (promoStatus) {
      if (allState.currency?.exchangeRateCurrent.RateText) {
        setRater(allState.currency?.exchangeRateCurrent.RateText)
        setReceiveAmount(
          transferAmount *
            parseFloat(allState.currency?.exchangeRateCurrent.RateText)
        )
        Swal.close()
      }
    }
  }, [allState.currency])

  const [existingAccounts, setExistingAccounts] = useState([
    // {
    // 	accountNo: "0000",
    // 	accountNm: "Jack Black",
    // 	bank: "Bangkok Bank"
    // },{
    // 	accountNo: "0001",
    // 	accountNm: "Jack White",
    // 	bank: "TISCO"
    // },{
    // 	accountNo: "0002",
    // 	accountNm: "Jack Grey",
    // 	bank: "Thai Military Bank"
    // },{
    // 	accountNo: "0003",
    // 	accountNm: "Jack Blue",
    // 	bank: "Citibank"
    // }
  ])

  function clearAccountDetails() {
    setAccountName("")
    setAccountNumber("")
  }

  useEffect(() => {
    const exhData = {
      FromCurrencyId: 2,
      ToCurrencyId: 1,
      TransferAmount: 0,
    }
    loadData().then()

    dispatch(getExchangeRate(exhData))
  }, [])

  useEffect(() => {
    const exhDataCurrent = {
      FromCurrencyId: 2,
      ToCurrencyId: 1,
      TransferAmount: 0,
      PromoCode: "",
    }

    dispatch(getCurrentExchangeRate(exhDataCurrent))
    dispatch(getPaymentMethod())
  }, [])

  const handleModal = load => {
    if (!localStorage.getItem("smartway_auth")) {
      navigate(
        typeof window !== "undefined" && window.location.href.includes("/th")
          ? "/th/login"
          : "/en/login"
      )
      return
    }

    //Swal.showLoading()
    if (!!load) {
      // loadData()
    }
    showTransferModal(!transferModal)
    showSelectAccount(true)
    showNewAccount(false)
    showExistingAccount(false)
    setActiveAccount("")
    //handleCancelModal()

    if (saveCreateAccount === true) {
      //	setExistingAccounts(existingAccounts.concat({accountNo: accountNumber, accountNm: accountName, bank: accountBank}))
      setExistingAccounts(allState.profile.profile.LstMemberAccount)
    }

    setSaveCreateAccount(false)
  }

  const filterPaymentMethodFromPromoCode = (
    paymentMethod = [],
    promoCode = [0]
  ) => {
    if (promoCode.length === 0) {
      return paymentMethod
    }
    let result = []
    paymentMethod.forEach(item => {
      promoCode.forEach(item2 => {
        if (item.PaymentMethodId === item2) {
          result.push(item)
        }
      })
    })
    return result
  }
  const handleCancelModal = () => {
    showReasonBox(false)
    setShowReasons(false)
    setAlertAmount(false)
    showTransferModal(!transferModal)
    showSelectAccount(true)
    showNewAccount(false)
    showExistingAccount(false)
    // setAccountName("")
    // setAccountNumber("")
    setActiveAccount("")
    setPoliForm(false)

    if (showReasons == false && alertAmount == false) {
      setAccountName("")
      setAccountNumber("")
      setActiveAccount("")
      setMemAccId("")
    }
    setReasonBoxValue("")
    setShowReasons(false)
  }

  const handleCancelModal1 = () => {
    showReasonBox(false)
    setAlertAmount(false)
    setShowReasons(false)
    showTransferModal(!transferModal)
    showSelectAccount(false)
    showNewAccount(false)
    showExistingAccount(false)
    setAccountName("")
    setAccountNumber("")
  }

  const handleNewAccount = () => {
    showSelectAccount(false)
    showNewAccount(true)
  }

  const handleExistingAccount = () => {
    showSelectAccount(false)
    showExistingAccount(true)
  }

  const handleActiveExistingAccount = (
    accountNo,
    accountNm,
    MemberAccountId
  ) => {
    //alert(accountNm)
    setAccountName(accountNm)
    setAccountNumber(accountNo)
    setActiveAccount(accountNo)
    setMemAccId(MemberAccountId)
  }

  const handleTransfer = async data => {
    showSelectAccount(false)
    const [addTransaction, error] = await dispatch(
      termAndCondition("dataCheck")
    )
    if (error) {
      setTimeout(() => {
        Swal.fire({
          title: "Error !!",
          text: error,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
        })
      }, 500)
      return
    }
    if (!addTransaction?.Object?.IsAcceptCurrentVersion) {
      setPopTermAndCondition(true)
      setTermAndConditionObject(addTransaction?.Object || {})
      return
    }

    const transferData = {
      MemberAccountId: memAccId,
      TransferAmount: parseFloat(transferAmount.toString().replace(",", "")),
      FromCurrencyId: 2,
      ToCurrencyId: 1,
      EnabledSaveFavorite: true,
    }

    const [overLimit, err] = await dispatch(checkOverLimit(transferData))
    if (err) {
      await Swal.fire({
        title: "Error !!",
        text: err,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
      })
      return
    }
    if (overLimit.StatusCode === "09") {
      setAlertAmount(true)
    } else {
      setShowReasons(true)
    }
    showTransferModal(!transferModal)
  }

  const startPoli = async data => {
    showSelectAccount(false)

    // const poliLink = await dispatch(poliPayment(data))
    const dataCheck = {
      MemberAccountId: memAccId,
      TransferAmount: parseFloat(transferAmount.toString().replace(",", "")),
      FromCurrencyId: 2,
      ToCurrencyId: 1,
      TransferObjectiveId: currentReason,
      TransferObjectiveRemark: currentReason == 1 ? reasonBoxValue : resonValue,
      PromoCode: promoCodeReal,
      PaymentMethodId: paymentMethodSelected,
      isWebApp: true,
    }

    Swal.showLoading()
    const [addTransaction, error] = await dispatch(
      transferMoneyCheck(dataCheck)
    )
    if (error) {
      setTimeout(() => {
        Swal.fire({
          title: "Error !!",
          text: error,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
        })
      }, 500)
      Swal.close()
      return
    }
    Swal.close()
    setSlipPreview(addTransaction?.Object)
    setPopSlipReview(true)
  }

  const handleShowReasons = () => {
    setAlertAmount(false)
    setShowReasons(true)
  }

  const handleReasonBox = value => {
    setResonValue(value)
    if (value == "1") {
      showReasonBox(true)
      setAlertAmount(false)
    } else {
      setReasonError(false)
      showReasonBox(false)
    }
  }

  const reasonBoxClasses = classNames({
    "show--reason-box": reasonBox,
  })

  const modalClasses = classNames(
    "transfer__modal--select-account",
    {
      "transfer__modal--select-account--small": selectAccount,
    },
    {
      "transfer__modal--select-account--small": alertAmount,
    },
    {
      "transfer__modal--select-account--small": showReasons,
    }
  )

  //useeffect for store
  const loadData = async () => {
    await Promise.all([
      dispatch(getAllBank()),
      dispatch(getStates()),
      dispatch(getProfile()),
      dispatch(getReason()),
    ])
  }
  const verifyPromoAndCalculate = async (transferAmount = 0, promo = "") => {
    setTransferBtnStatus(false)
    if (!transferAmount) {
      Swal.fire(
        translate("please fill sending amount", "กรุณากรอกข้อมูลให้ครบถ้วนก่อน")
      )
      // setTransferBtnStatus(true)
      return
    }
    if (promo) {
      Swal.fire({
        title: "Please Wait!",
        html: "Verifying Promo Code",
        allowOutsideClick: false,
        showConfirmButton: false,
      }).then()
      const exhData = {
        FromCurrencyId: 2,
        ToCurrencyId: 1,
        TransferAmount: transferAmount.toString().replace(",", ""),
        PromoCode: promo,
      }
      const [getPromoData, error] = await dispatch(checkPromoCode(exhData))
      if (error) {
        Swal.close()
        setPromoStatus(false)
        setPromoCodeReal("")
        setPromoValid("Invalid")
        setRater(allState.currency?.exchangeRateCurrent.RateText)
        setReceiveAmount(
          parseFloat(transferAmount.toString().replace(",", "")) *
            allState.currency?.exchangeRateCurrent.Rate
        )
        setMethodFromPromoCode([])
        setTransferBtnStatus(false)
      } else {
        Swal.close()
        setPromoValue(promo)
        setPromoCodeReal(promo)
        setPromoStatus(true)
        setPromoValid("Valid")
        setRater(getPromoData?.Object.RateText)
        setReceiveAmount(
          parseFloat(transferAmount.toString().replace(",", "")) *
            getPromoData?.Object.Rate
        )
        setMethodFromPromoCode(getPromoData?.Object?.PaymentMethods)
        setTransferBtnStatus(true)
      }
    } else {
      //reset to default
      setPromoStatus(true)
      setPromoCodeReal("")
      setPromoValue("")
      setPromoValid("")
      setTransferBtnStatus(true)
      setRater(allState.currency?.exchangeRateCurrent.RateText)
      setReceiveAmount(
        parseFloat(transferAmount.toString().replace(",", "")) *
          allState.currency?.exchangeRateCurrent.Rate
      )
      setMethodFromPromoCode([])
    }
  }

  useEffect(() => {
    dispatch(getProfile())
  }, [transferModal])

  const SetExchangeRate = async (amt = "0") => {
    let tfAmount = parseInt(amt.replace(/[^0-9\\.]+/g, ""), 10)
    if (isNaN(tfAmount)) {
      return
    }
    const exhData = {
      FromCurrencyId: 2,
      ToCurrencyId: 1,
      TransferAmount: tfAmount,
    }
    const [result, error] = await dispatch(getCurrentExchangeRate2(exhData))
    if (!error) {
      if (result?.data?.Object?.Rate) {
        setReceiveAmount(transferAmount * result?.data?.Object?.Rate)
        setRater(result?.data?.Object?.Rate.toFixed(2))
      }
    }
  }
  // on Set Value Change
  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      let tfAmount = parseInt(
        changeTransferAmount.replace(/[^0-9\\.]+/g, ""),
        10
      )
      setTransferAmount(
        tfAmount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )
      await SetExchangeRate(changeTransferAmount)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [changeTransferAmount])

  return (
    <>
      {popTermAndCondition && (
        <TermAndCondition
          handleCancelNormalModal={() => setPopTermAndCondition(false)}
          handleSuccessMessage={() => {
            handleTransfer(transferValues)
            setPopTermAndCondition(false)
          }}
          bodyHtml={termAndConditionObject?.TermBody}
          title={termAndConditionObject?.TermTitle}
          termID={termAndConditionObject?.TermAndConditionId}
        />
      )}
      {popSlipReview && (
        <SlipPreviewComponent
          slip={slipPreview}
          handleCancelNormalModal={() => {
            setPopSlipReview(false)
            window.location.reload()
          }}
        />
      )}
      <section className="money-transfer" style={{ width: "100%" }}>
        <div>
          <h2>{translate("Transfer Money", "โอนเงินกับเรา")}</h2>
          <h3>
            {translate(
              `Send money overseas online from Australia to Thailand with the best
          currency exchange rates around.`,
              `บริการโอนเงินออนไลน์จากออสเตรเลียกลับไทยด้วยเรทที่ดีที่สุด`
            )}
          </h3>
        </div>

        <Formik
          enableReinitialize
          initialValues={transferValues}
          validateOnChange={true}
          validate={async values => {
            const errors = {}
            values.accountNumbers = accountNumber
            values.accountNames = accountName
            values.send = transferAmount

            if (!values.accountNumbers || values.accountNumbers === "") {
              errors.accountNumbers = "Required"
            }
            if (!values.accountNames || values.accountNames === "") {
              errors.accountNames = "Required"
            }
            if (!values.send) {
              errors.send = "Required"
            }
            if (
              parseFloat(values.send.toString().replace(",", "")) > 999999.99
            ) {
              errors.send = "Amount must be between 00.00 and 999,999.99"
            }
            if (parseFloat(values.send.toString().replace(",", "")) < 0) {
              errors.send = "Amount must be between 00.00 and 999,999.99"
            }

            // if (!values.login) {
            //   showLoginBox(true)
            // }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (values.promo) {
              const exhData = {
                FromCurrencyId: 2,
                ToCurrencyId: 1,
                TransferAmount: transferAmount.toString().replace(",", ""),
                PromoCode: values.promo,
              }
              const [getPromoData, error] = await dispatch(
                checkPromoCode(exhData)
              )
              if (error) {
                Swal.fire({
                  title: "Warning !!",
                  text: "Please enter valid promo code",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                })
                return
              }
            }
            if (
              profile.profile.MemberStatusId === 1 &&
              profile.profile.MemberProfileStatusId !== 3
            ) {
              Swal.fire({
                title: "Your Profile is not 100% complete",
                text: "Please complete your profile",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Update Profile",
                showCloseButton: true,
              }).then(result => {
                if (result.isConfirmed) {
                  navigate(
                    typeof window !== "undefined" &&
                      window.location.href.includes("/th")
                      ? "/th/register2"
                      : "/en/register2"
                  )
                }
              })
            } else if (
              profile.profile.MemberStatusId === 1 &&
              profile.profile.MemberProfileStatusId === 3
            ) {
              Swal.fire({
                title: "Your Profile waiting for approval",
                text: "Please see your  status",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "check status",
                showCloseButton: true,
              }).then(result => {
                if (result.isConfirmed) {
                  navigate(
                    typeof window !== "undefined" &&
                      window.location.href.includes("/th")
                      ? "/th/profile"
                      : "/en/profile"
                  )
                }
              })
            } else if (profile.profile.MemberStatusId === 2) {
              setTransferValues(values)
              handleTransfer(values)
            } else {
              Swal.fire({
                title: "You are not allowed to make Transfer",
                text: "Kindly reach out to admin team",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
              })
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="currency-group">
                  <label htmlFor="">{translate("You Send", "ส่งเงิน")}</label>
                  <div className="currency-input">
                    <input
                      placeholder={""}
                      value={transferAmount}
                      onClick={e => {
                        e.target.setSelectionRange(0, e.target.value.length)
                      }}
                      defaultValue={Number(transferAmount).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                      onBlur={async e => {
                        handleBlur(e)
                        // setChangeTransferAmount(e.target.value)
                      }}
                      // onFocus={e => {
                      //   setYouSendFocus(e.target.name)
                      // }

                      onChange={async e => {
                        setFieldValue("promo", "")
                        setPromoValid("")
                        setPromoStatus(false)
                        setTransferAmount(e.target.value)
                        //  handleInputChange(e)
                        handleChange(e)
                      }}
                      // onFocus={(e)=>parseInt(e.target.value)}
                      onKeyPress={event => {
                        if (!/[0-9.,]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onKeyUp={event => {
                        SetExchangeRate(event.target.value)
                      }}
                      type="text"
                      name="send"
                    />
                    <span>AUD</span>
                  </div>
                  <div className="error-form">
                    {errors.send && touched.send && errors.send}
                  </div>
                </div>
                <div className="currency-group">
                  <label htmlFor="">
                    {translate("Recipient Get", "รับเงิน")}
                  </label>
                  <div className="currency-input">
                    <input
                      placeholder={String(receiveAmount.toFixed(2)).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      value={String(receiveAmount.toFixed(2)).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      readOnly
                    />
                    <span>THB</span>
                  </div>
                </div>
              </div>
              {allState?.profile?.profile.MemberId != null && (
                <div className="account_inputs_design">
                  <div className="currency-group currency-group--transfer">
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "15px",
                        lineHeight: "18px",
                        letterSpacing: " 1px",
                        display: "inline-block",
                        marginBottom: "8px",
                        color: "#45578C",
                        fontWeight: "bold",
                      }}
                    >
                      {translate("Account Name", "ชื่อบัญชี")}
                    </label>
                    <div
                      className="currency-input"
                      style={{
                        display: "flex",
                        alignItems: " center",
                        height: "60px",
                        border: "1px solid #E2E2E2",
                      }}
                    >
                      <input
                        type="text"
                        name="accountName"
                        onChange={e => {
                          handleChange(e)
                          setFieldValue("accountName", accountName)
                          values.accountName = accountName
                        }}
                        onBlur={handleBlur}
                        value={accountName}
                        readOnly={true}
                        onClick={() => handleModal(true)}
                        style={{
                          outline: "none",
                          minWidth: "150px",
                          width: "100%",
                          padding: " 0 10px",
                          color: " #214DCE",
                          fontSize: "18px",
                          height: " 100%",
                          textAlign: "left",
                          letterSpacing: "1px",
                          border: "none",
                        }}
                      />
                    </div>
                    <div className="error-form">
                      {accountName === "" &&
                        errors.accountNames &&
                        touched.accountNames &&
                        errors.accountNames}
                    </div>
                  </div>

                  <div className="currency-group currency-group--transfer">
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "15px",
                        lineHeight: "18px",
                        letterSpacing: " 1px",
                        display: "inline-block",
                        marginBottom: "8px",
                        color: "#45578C",
                        fontWeight: "bold",
                      }}
                    >
                      {translate("Account Number", "หมายเลขบัญชี")}
                    </label>
                    <div
                      className="currency-input"
                      style={{
                        display: "flex",
                        alignItems: " center",
                        height: "60px",
                        border: "1px solid #E2E2E2",
                      }}
                    >
                      <input
                        type="text"
                        name="accountNumber"
                        onChange={e => {
                          handleChange(e)
                          values.accountNumber = accountNumber
                        }}
                        onBlur={handleBlur}
                        value={accountNumber}
                        readOnly={true}
                        onClick={() => handleModal(true)}
                        style={{
                          outline: "none",
                          minWidth: "150px",
                          width: "100%",
                          padding: " 0 10px",
                          color: " #214DCE",
                          fontSize: "18px",
                          height: " 100%",
                          textAlign: "left",
                          letterSpacing: "1px",
                          border: "none",
                        }}
                      />
                    </div>
                    <div className="error-form">
                      {accountNumber == "" &&
                        errors.accountNumbers &&
                        touched.accountNumbers &&
                        errors.accountNumbers}
                    </div>
                  </div>

                  <div className="currency-group currency-group--transfer currency-group--promo">
                    <label htmlFor="">
                      {translate("Promo Code", "รหัสโปรโมชั่น")}
                    </label>
                    <div className="currency-input">
                      <input
                        name="promo"
                        onChange={e => {
                          setPromoValid("")
                          if (e.target.value.length > 0) {
                            setTransferBtnStatus(false)
                          } else {
                            setTransferBtnStatus(true)
                          }
                          setReceiveAmount(
                            parseFloat(
                              transferAmount.toString().replace(",", "")
                            ) * allState.currency?.exchangeRateCurrent.Rate
                          )
                          setRater(
                            allState.currency?.exchangeRateCurrent.RateText
                          )
                          handleChange(e)
                        }}
                        onBlur={async e => {
                          handleBlur(e)
                          if (!e.target.value.length) {
                            await verifyPromoAndCalculate(
                              transferAmount,
                              values.promo
                            )
                          }
                        }}
                        value={values.promo}
                        type="string"
                      />
                      <span
                        onClick={async () => {
                          await verifyPromoAndCalculate(
                            transferAmount,
                            values.promo
                          )
                        }}
                        style={{ width: "170px", textAlign: "center" }}
                      >
                        {translate("Apply", "ตรวจสอบ")}
                      </span>
                    </div>
                    {promoValid == "Valid" && (
                      <div className="promo__validate">
                        <img src={promoValidImg} className="ASIC-logo" />
                        {translate(
                          "You have received special rate",
                          "คุณได้รับเรทพิเศษ"
                        )}
                      </div>
                    )}
                    {promoValid == "Invalid" && (
                      <div className="promo__validate">
                        <img src={promoInvalidImg} className="ASIC-logo" />
                        {translate(
                          "Your promotion code is invalid",
                          "รหัสโปรโมชันไม่ตรงตามเงื่อนไข"
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div>
                <div className="error-form">
                  {errors.login && touched.login && errors.login}
                </div>
                <div className="money-transfer__footer">
                  {/*<img src={asicLogo} className="ASIC-logo" />*/}
                  <img src={payidLogo} className="ASIC-logo" />
                  {/*<img src={poliLogo} className="ASIC-logo" />*/}

                  <p>
                    <b>{translate("Our Rate", "อัตราแลกเปลี่ยน")} </b>
                    {rater}
                  </p>
                  <button
                    className="btn btn--transfer bold btn--yellow"
                    type="submit"
                    onClick={e => {
                      if (
                        typeof window !== "undefined" &&
                        localStorage.getItem("smartway_auth") == null
                      ) {
                        showLoginBox(true)
                      }
                      handleSubmit(e)
                    }}
                    disabled={!transferBtnStatus}
                  >
                    {translate("Transfer", "โอนเงิน")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </section>
      {!!allState.currency.exchangeRate.length > 0 && (
        <div className="rates__container">
          <div className="rates__item rates__item--highlight">
            <div>{translate("Special Rates", "อัตราแลกเปลี่ยนพิเศษ")}</div>
          </div>

          {allState.currency.exchangeRate.map((data, index) => {
            return (
              index < 4 && (
                <div className="rates__item" key={data.id + index.toString()}>
                  <div>
                    {data.FromAmountText.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    -{" "}
                    {data.ToAmountText.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {data.FromAmount > 50000 ? "Up" : null} ={" "}
                    {data.Rate.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              )
            )
          })}
        </div>
      )}

      {loginBox && (
        <div className="transfer__modal">
          <div className={modalClasses}>
            <div
              className="transfer__modal--cross"
              onClick={() => showLoginBox(false)}
              style={{ top: "20px", right: "20px" }}
            >
              <img src={crossIcon} className="transfer__modal--cross-img" />
            </div>
            <h3 style={{ marginTop: "0" }}>Please login to continue</h3>
            <p>To transfer money, you must first login.</p>
            {typeof window !== "undefined" &&
            window.location.href.includes("/th") ? (
              <LinkHandler linkUrl="/th/login" className="btn bold btn--yellow">
                {translate("Login", "เข้าสู่ระบบ")}
              </LinkHandler>
            ) : (
              <LinkHandler linkUrl="/en/login" className="btn bold btn--yellow">
                {translate("Login", "เข้าสู่ระบบ")}
              </LinkHandler>
            )}
          </div>
        </div>
      )}
      {transferModal && (
        <div className="transfer__modal">
          <div className={modalClasses}>
            <div
              className="transfer__modal--cross"
              onClick={() => handleCancelModal()}
            >
              <img src={crossIcon} className="transfer__modal--cross-img" />
            </div>
            {selectAccount && (
              <>
                <h3>
                  {translate("Choose Recipient Account", "เลือกบัญชีผู้รับ")}
                </h3>
                <span
                  className="transfer__ghost-button"
                  onClick={handleNewAccount}
                >
                  {translate(" Create New Account", "สร้างบัญชีใหม่")}
                </span>
                <span
                  className="transfer__ghost-button"
                  onClick={handleExistingAccount}
                >
                  {translate("Use Existing Account", "ใช้บัญชีที่มีอยู่")}
                </span>
              </>
            )}
            {newAccount && (
              <div className="account__select account__select--create">
                <h3>{translate("Create New Account", "สร้างบัญชีใหม่")}</h3>
                <p>
                  {translate(
                    "Please fill in English",
                    "กรุณากรอกข้อมูลทั้งหมดเป็นภาษาอังกฤษ"
                  )}
                </p>

                <Formik
                  initialValues={{
                    name: "",
                    account_number: "",
                    account_nick_name: "",
                    bank: null,
                    city_address: "",
                    state: "",

                    city: "",
                    postal_code: "",
                    saved: false,
                    bankId: null,
                  }}
                  validate={values => {
                    const errors = {}
                    if (!values.name) {
                      errors.name = "Required"
                    } else if (!englishCheckNotNumber.test(values.name)) {
                      errors.name = "Please fill in English"
                    }
                    if (!values.account_number) {
                      errors.account_number = "Required"
                    }else if (!/^\d+$/.test(values.account_number)) {
                      errors.account_number = translate("Please fill in number", "กรุณากรอกตัวเลข")
                    }
                    if (
                      !englishCheckNotSpecial.test(values.account_nick_name)
                    ) {
                      errors.account_nick_name = "Please fill in English"
                    }
                    if (!values.bankId) {
                      errors.bankId = "Required"
                    }
                    if (!values.city) {
                      errors.city = "Required"
                    }

                    if (!values.postal_code) {
                      errors.postal_code = "Required"
                    }
                    if (!values.state) {
                      errors.state = "Required"
                    }
                    if (!values.city_address) {
                      errors.city_address = "Required"
                    } else if (!englishCheck.test(values.city_address)) {
                      errors.city_address = "Please fill in English"
                    }

                    return errors
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    const {
                      profile: { profile },
                    } = allState
                    const LstMemberAccount = [
                      ...profile.LstMemberAccount,
                      {
                        AccountNo: values.account_number,
                        AccountName: values.name,
                        AccountMemo: values.account_nick_name,
                        Address: values.city_address,
                        AmphurId: values.city,
                        PostalCodeId: values.postal_code,
                        IsSaveFavorite: values.saved,
                        BankId: values.bankId,
                        ProvinceId: values.state,
                        EnabledSaveFavorite: true,
                      },
                    ]
                    profile.EnabledSaveFavorite = true
                    let [res, errEditProfile] = await dispatch(
                      moneyTransferEditProfile({
                        ...profile,
                        LstMemberAccount,
                      })
                    )
                    if (errEditProfile) {
                      Swal.fire({
                        title: "Error !!",
                        text: errEditProfile,
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                      })
                      return
                    }
                    setAccountName(values.name)
                    setAccountNumber(values.account_number)
                    let [resGetProfile, errGetProfile] = await dispatch(
                      moneyTransferGetProfile()
                    )
                    if (errGetProfile) {
                      Swal.fire({
                        title: "Error !!",
                        text: errGetProfile,
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                      })
                      return
                    }
                    //get current account
                    const currentAccount = resGetProfile.Object.LstMemberAccount.filter(
                      item => {
                        return (
                          item.AccountNo.toString() ===
                            values.account_number.toString() &&
                          item.AccountName === values.name
                        )
                      }
                    )
                    setTimeout(() => {
                      if (currentAccount.length > 0) {
                        setMemAccId(currentAccount[0].MemberAccountId)
                      }
                    }, 500)
                    showSelectAccount(false)
                    showNewAccount(false)
                    showTransferModal(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="currency-group">
                        <div className="currency-input">
                          <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            placeholder={translate("Account Name", "ชื่อบัญชี")}
                          />
                        </div>
                        <div className="error-form">
                          {errors.name && touched.name && errors.name}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <input
                            placeholder={translate(
                              "Account Number",
                              "เลขที่บัญชี"
                            )}
                            type="text"
                            name="account_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.account_number}
                          />
                        </div>
                        <div className="error-form">
                          {errors.account_number &&
                            touched.account_number &&
                            errors.account_number}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <input
                            type="text"
                            name="account_nick_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.account_nick_name}
                            placeholder={translate(
                              "Account Nickname",
                              "ชื่อเรียกบัญชี"
                            )}
                          />
                        </div>

                        <div className="error-form">
                          {errors.account_nick_name &&
                            touched.account_nick_name &&
                            errors.account_nick_name}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <select
                            name="bank"
                            onChange={e => {
                              setFieldValue("bankId", e.target.value)
                            }}
                            onBlur={handleBlur}
                            value={
                              typeof window !== "undefined" &&
                              window.location.href.includes("/th")
                                ? values?.bank?.BankName_th_TH
                                : values?.bank?.BankName
                            }
                          >
                            <option
                              selected={!values.bankId}
                              value=""
                              disabled={true}
                            >
                              {translate("Bank", "ชื่อธนาคาร")}
                            </option>
                            {allState.currency.bank &&
                              allState.currency.bank.map(data => {
                                return (
                                  <option id={data.BankId} value={data.BankId}>
                                    {typeof window !== "undefined" &&
                                    window.location.href.includes("/th")
                                      ? data.BankName_th_TH
                                      : data.BankName}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                        <div className="error-form">
                          {errors.bankId && touched.bankId && errors.bankId}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <input
                            placeholder={translate("Address", "ที่อยู่")}
                            type="text"
                            name="city_address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city_address}
                          />
                        </div>
                        <div className="error-form">
                          {errors.city_address &&
                            touched.city_address &&
                            errors.city_address}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <select
                            name="state"
                            onChange={e => {
                              handleChange(e)
                              dispatch(getCity(e.target.value))
                            }}
                            onBlur={handleBlur}
                            value={values.state}
                          >
                            <option value="" disabled={translate()}>
                              {translate("State/Province", "จังหวัด")}
                            </option>
                            {allState.currency.states &&
                              allState.currency.states.map(data => {
                                return (
                                  <option
                                    id={data.ProvinceId}
                                    value={data.ProvinceId}
                                  >
                                    {data.ProvinceName}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                        <div className="error-form">
                          {errors.state && touched.state && errors.state}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <select
                            name="city"
                            onChange={e => {
                              handleChange(e)
                              dispatch(getPostel(e.target.value))
                            }}
                            onBlur={handleBlur}
                            value={values.city}
                          >
                            <option value="" disabled={true}>
                              {translate("City", "อำเภอ")}
                            </option>
                            {allState.currency.cities &&
                              allState.currency.cities.map(data => {
                                return (
                                  <option
                                    id={data.AmphurId}
                                    value={data.AmphurId}
                                  >
                                    {data.AmphurName}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                        <div className="error-form">
                          {errors.city && touched.city && errors.city}
                        </div>
                      </div>
                      <div className="currency-group">
                        <div className="currency-input">
                          <select
                            name="postal_code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.postal_code}
                          >
                            <option value="" disabled={true}>
                              {translate("Postal Code", "รหัสไปรษณีย์")}
                            </option>
                            {allState.currency.postel &&
                              allState.currency.postel.map(data => {
                                return (
                                  <option
                                    id={data.PostalCodeId}
                                    value={data.PostalCodeId}
                                  >
                                    {data.PostalCodeName}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                        <div className="error-form">
                          {errors.postal_code &&
                            touched.postal_code &&
                            errors.postal_code}
                        </div>
                      </div>
                      <div className="save-account">
                        <input
                          type="checkbox"
                          name="saved"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.saved}
                        />
                        <span>{translate("Save account", "บันทึกบัญชี")}</span>
                      </div>

                      <span
                        className="transfer__ghost-button"
                        onClick={handleCancelModal}
                      >
                        {translate("Cancel", "ยกเลิก")}
                      </span>
                      <button type="submit" className="btn bold btn--yellow">
                        {translate("Save", "บันทึก")}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            )}
            {existingAccount && (
              <div className="account__select account__select--exist">
                <h3>
                  {translate("Choose Existing Account", "เลือกบัญชีที่มีอยู่")}
                </h3>
                <p>{translate("Please select an account", "โปรดเลือกบัญชี")}</p>

                {/* {
                } */}

                {allState.profile.profile &&
                  allState.profile.profile.LstMemberAccount &&
                  allState.profile.profile.LstMemberAccount.filter(
                    item => item.IsSaveFavorite == true
                  ).map(
                    (
                      {
                        AccountNo,
                        AccountName,
                        BankIdBankName,
                        MemberAccountId,
                      },
                      i
                    ) => (
                      <ExistingAccount
                        existingAccountCheck={activeExistingAccount}
                        accountNo={AccountNo}
                        accountNm={AccountName}
                        bank={BankIdBankName}
                        id={MemberAccountId}
                        showSelectAccount={true}
                        clearAccDet={clearAccountDetails}
                        key={i}
                        onClick={() =>
                          handleActiveExistingAccount(
                            AccountNo,
                            AccountName,
                            MemberAccountId
                          )
                        }
                      />
                    )
                  )}

                {/* <ExistingAccount existingAccountCheck={activeExistingAccount2} accountNo="0000-00000002" accountNm="Jack White" bank="Thai Bank" onClick={handleActiveExistingAccount2}/>
					<ExistingAccount existingAccountCheck={activeExistingAccount3} accountNo="0000-00000003" accountNm="Jack Grey" bank="Thai Bank" onClick={handleActiveExistingAccount3}/>
					<ExistingAccount existingAccountCheck={activeExistingAccount4} accountNo="0000-00000004" accountNm="Jack Blue" bank="Thai Bank" onClick={handleActiveExistingAccount4}/> */}

                <span
                  className="transfer__ghost-button"
                  onClick={handleCancelModal}
                >
                  {translate("Cancel", "ยกเลิก")}
                </span>
                <a
                  className="btn bold btn--yellow"
                  onClick={() => handleModal(false)}
                >
                  {translate("Continue", "บันทึก")}
                </a>
              </div>
            )}
            {alertAmount && (
              <>
                <h3>
                  {translate(
                    "Your transfer amount exceeds $10,000 AUD within 48 hours. To proceed, you will need to provide the following documents:",
                    "ยอดโอนเงินของคุณเกิน $10,000 AUD ภายใน 48 ชั่วโมงแล้ว เพื่อดำเนินการต่อ คุณจะต้องส่งเอกสารดังต่อไปนี้:"
                  )}
                </h3>
                <p>
                  {translate(
                    "1. Bank statement from the last three months to present.",
                    "1. รายการเดินบัญชี ตั้งแต่ 3 เดือนล่าสุดจนถึงปัจจุบัน"
                  )}
                </p>
                <p>
                  {translate(
                    "2. Pay slip from last month or the last four weeks.",
                    "2. สลิปเงินเดือน ของเดือนที่แล้ว หรือ 4 สัปดาห์ที่ผ่านมา"
                  )}
                </p>
                <span
                  className="transfer__ghost-button"
                  onClick={handleShowReasons}
                >
                  {translate("OK", "ตกลง")}
                </span>
                <span
                  className="transfer__ghost-button"
                  onClick={handleCancelModal}
                >
                  {translate("Cancel", "ยกเลิก")}
                </span>
              </>
            )}
            {showReasons && (
              <>
                <h2>{translate("Payment Methods", "วิธีการชำระเงิน")}</h2>
                {filterPaymentMethodFromPromoCode(
                  currencyState.paymentMethods,
                  methodFromPromoCode
                )
                  // .filter(d => d.PaymentMethodId === 2)
                  .map(data => {
                    return (
                      <div>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          {data.PaymentMethodId === paymentMethodSelected ? (
                            <img src={radioSelected} className="ASIC-logo" />
                          ) : (
                            <img
                              src={radioUnselected}
                              onClick={() => {
                                setPaymentMethodSelected(data.PaymentMethodId)
                              }}
                              className="ASIC-logo"
                            />
                          )}
                          <img
                            src={data.PaymentLogo}
                            onClick={() => {
                              setPaymentMethodSelected(data.PaymentMethodId)
                            }}
                            style={{
                              width: "100px",
                              marginLeft: "15px",
                            }}
                          />
                        </div>
                      </div>
                    )
                  })}
                <h3>{translate("", "")}Choose your reason for transfer:</h3>
                <div className="currency-group currency-group-reason">
                  <div>
                    <select
                      onChange={e => {
                        handleReasonBox(e.target.value)
                        changeCurrentReason(e.target.value)
                      }}
                    >
                      <option defaultValue value="Bank">
                        {translate("", "")}Please select the reason for transfer
                      </option>
                      {!!allState.profile.reasons &&
                        allState.profile.reasons.map(data => {
                          return (
                            <option
                              value={data.TransferObjectiveId}
                              // onChange={changeCurrentReason(data.TransferObjectiveName)}
                            >
                              {data.TransferObjectiveName}
                            </option>
                          )
                        })}
                    </select>
                    {currentReason == 1 && (
                      <>
                        <input
                          value={reasonBoxValue}
                          onChange={e => {
                            setReasonBoxValue(e.target.value)
                          }}
                          className={reasonBoxClasses}
                          required
                        />
                      </>
                    )}
                    {reasonError && (
                      <>
                        <div className="error-form">Required</div>
                      </>
                    )}
                  </div>
                </div>
                <span
                  className="transfer__ghost-button"
                  onClick={() => {
                    if (currentReason == 1 && reasonBoxValue == "") {
                      setReasonError(true)
                    } else {
                      if (!!resonValue) {
                        setReasonError(false)
                        setShowReasons(false)
                        setPoliForm(true)
                        startPoli(transferValues)
                        // if(transferURL != "") {
                        //   if (typeof window !== "undefined") {
                        //     window.location = transferURL
                        //   }
                        // }
                        // window.open(poliLinker,'_blank');
                        showTransferModal(false)
                      }
                    }
                  }}
                >
                  {translate("", "")}OK
                </span>
              </>
            )}
            {/* {poliForm && (

              <div className="poliform">
                <h3>Below is you Poli form Details</h3>
                <span
                  className="transfer__ghost-button"
                  onClick={()=>{

                  }}
                >
                  Proceed to Reciept
                </span>
              </div>
            )

            } */}
          </div>
        </div>
      )}
    </>
  )
}

export default MoneyTransfer
