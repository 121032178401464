import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import translate from "../../helpers/language"

const RegistrationSuccessful = () => {
  return (
    <>
      <section className="login-money-transfer">
        <h1>{translate("Email Verified", "ยืนยันอีเมลเรียบร้อย")}</h1>
        <p>{translate("Your email address has been verified successfully.", "อีเมลของคุณได้รับการยืนยันเรียบร้อย")}</p>

        <Link
          style={{ alignSelf: "center", marginTop: "30px" }}
          type="submit"
          className="btn bold btn--yellow"
          to={
            typeof window !== "undefined" &&
            window.location.href.includes("/th")
              ? "/th/login"
              : "/en/login"
          }
        >
          Login
        </Link>
      </section>
    </>
  )
}

export default RegistrationSuccessful
