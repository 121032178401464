export const DocumentStruct = {
  MemberDocumentId: null,
  MemberDocumentName: null,
  MemberId: null,
  MemberDocumentTypeId: 4,
  DocumentNo: "1111111",
  DocumentExpireDate: "/Date(1706547600000)/",
  DocumentExpireDateText: "30/01/2024",
  DocumentExpireDateISO: "2024-01-30 00:00:00",
  DocumentHyperlink:
    "https://storage.googleapis.com/smartway/images/ead9ed59bd1e4f6ab94e63b5a7f94e43240108_105457.jpg",
  DocumentBackHyperlink:
    "https://storage.googleapis.com/smartway/images/cb8e544bfd404751aeab92b09698a798240108_105458.jpg",
  DocumentIssuer: "111",
  HasDocument: false,
  DocumentIssueDate: null,
  DocumentIssueDateText: "",
  DocumentIssueDateISO: null,
  DocumentStateOrCountry: null,
  MemberIdMemberName: null,
  MemberDocumentTypeIdMemberDocumentTypeName: null,
  Dup_MemberId: 0,
  Dup_MemberName: null,
  Dup_MemberDocumentName: null,
  Dup_DocumentNo: null,
  Member: null,
  DocumentBase64: null,
  DocumentName: null,
  DocumentBackBase64: null,
  DocumentBackName: null,
  ErrorStateModel: null,
  IsHiddenName: false,
  IsHiddenDisplayIndex: false,
  VersionNumber: null,
  OrganizationId: null,
  ActiveStatusId: null,
  DisplayIndex: null,
  CreatedOn: null,
  CreatedOnText: "",
  CreatedOnISO: null,
  CreatedOnTimeText: "",
  CreatedBy: null,
  ModifiedOn: null,
  ModifiedOnText: "",
  ModifiedOnISO: null,
  ModifiedOnTimeText: "",
  ModifiedBy: null,
  OrganizationName: null,
  ActiveStatusName: null,
  CreatedBySystemUserName: null,
  ModifiedBySystemUserName: null,
  TokenId: null,
  Language: null,
}
export const ApiObjectDocumentStruct = {
  Object: {
    MemberId: 41613,
    Documents: [DocumentStruct],
  },
  StatusCode: "0",
  Message: null,
}
