import React, { useEffect } from "react"
import { RunMode } from "../config-build"

const RemoveLog = () => {
  useEffect(() => {
    if (RunMode !== "development"){
      if (!console.backUplog){
        console.backUplog = console.log
        console.log = function(...args) {}
        console.backUplog("DEBUG:", "This is a test log")
      }
      console.backUplog("DEBUG:", process.env.GATSBY_MODE)
    }
  }, [])

  return <></>
}
export default RemoveLog
