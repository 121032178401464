const English = "EN"

function translate(en, thi, lang = English) {
  if (typeof window !== "undefined") {
    if (window.location.href.includes("/th")) {
      return thi
    } else {
      return en
    }
  } else {
    if (lang === English) {
      return en
    } else {
      return thi
    }
  }
}

export default translate
