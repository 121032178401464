import React, { useEffect, useRef, useState } from "react"
require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})
const FacebookComponent = () => {
  const [path, setPath] = useState("")
  // useEffect window.location.href
  useEffect(() => {
    setInterval(() => {
      setPath(window.location.href)
    }, 1000)
  }, [])

  const MessengerRef = useRef()
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return
    }
    console.log("DEBUG: useEffect Facebook")
    let fbChat = window.document.getElementById("fb-customer-chat")
    // setLiveUrl(window.location.href)
    let fbLanguage = "en_US"
    const currLang =
      typeof window !== "undefined" && window.location.href.includes("/th")
        ? "TH"
        : "EN"
    currLang.toLocaleLowerCase() === "th"
      ? (fbLanguage = "th_TH")
      : (fbLanguage = "en_US")

    if (fbChat) {
      fbChat.setAttribute("page_id", "138557413571874")
      fbChat.setAttribute("attribution", "biz_inbox")
      // window.FB.XFBML.parse();
    } else {
      //add fb chat
      let fbChat = document.createElement("div")
      fbChat.setAttribute("id", "fb-customer-chat")
      fbChat.setAttribute("class", "fb-customerchat")
      fbChat.setAttribute("page_id", "138557413571874")
      fbChat.setAttribute("attribution", "biz_inbox")
      document.body.appendChild(fbChat)
      // window.FB.XFBML.parse();
    }
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml: true,
        version: "v15.0",
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]

      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = `https://connect.facebook.net/${fbLanguage}/sdk/xfbml.customerchat.js`
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")

    // get status
  }, [path])

  return (
    <div>
      {/*<div id="fb-root"></div>*/}
      {/*<div id="fb-customer-chat" className="fb-customerchat"></div>*/}
    </div>
  )
}
export default FacebookComponent
