import React from "react"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"
import { default as GatsbyImage } from "gatsby-image"

const Image = ({ src, row, col, style }) => {
  const classes = classNames(row, col)

  const images = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { relativePath: { regex: ".*/" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 558, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  `)

  const image = images.allImages.edges.find(n => {
    return n.node.relativePath.includes(src)
  })
  if (!image) return null

  return !image.node.childImageSharp && image.node.extension === "svg" ? (
    <img src={image.node.publicURL} style={style} />
  ) : (
    <img
      className={classes}
      src={image.node.publicURL}
      style={{
        width: "100%",
        height: "inherit",
        top: "auto",
      }}
    />
  )
}

export default Image
