import React, { useEffect, useState } from "react"
import * as moment from "moment/moment"

const DateComponent = props => {
  useEffect(() => {
    // console.clear()
    console.log("DEBUG: props.value", props.placeholder, props.value)
    if (!props.value) {
      setValue("")
    } else if (value !== props.value) {
      setValue(props.value)
    }
  }, [props.value])

  const [value, setValue] = useState(props.value || "")
  return (
    <div className="currency-group currency-group--transfer currency-group--promo">
      <div
        className="currency-input currency-input--dateselector"
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            width: "fit-content",
            // backgroundColor:"red",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            left: "65px",
            zIndex: "1",
          }}
        >
          <label
            htmlFor="inputDob"
            style={{
              color: "#214dce",
              fontSize: "18px",
              fontWeight: "400",
              margin: 0,
              paddingLeft: "10px",
            }}
          >
            {value ? moment(value).format("MM/DD/YYYY") : props.placeholder}
          </label>
        </div>
        <input
          style={{
            color: "white",
            position: "absolute",
            left: "65px",
            backgroundColor: "transparent",
            width: "fit-content",
            textAlign: "center",
            fontSize: "0px",
          }}
          id={props.id}
          key={props.id}
          placeholder={props.placeholder}
          type="date"
          name={props.name}
          onChange={e => {
            setValue(e.target.value)
            props.handleChange(e)
          }}
          value={value}
        />
      </div>
      <div className="error-form">{props.error}</div>
    </div>
  )
}

export default DateComponent
